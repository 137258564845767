import React from 'react'

function Alert(props) {
    const renderMessage = () => {
        switch (props.content.type) {
            case 'success_update_account':
                return <span>Account details updated.</span>
            case 'error_login':
                return <span>Inavild email or password.</span>
            case 'error_close_markers':
                return (
                    <div>
                        <span>Pinned restaurant is too close with </span> 
                        {props.content.data.map((data, i) =>
                            <span key={i}>
                                <a href={data.link} target='_blank' rel="noreferrer">
                                    {data.name}
                                </a>{i < props.content.data.length - 1 ? ', ' : null}
                            </span>
                        )}
                    </div>
                )
            default:
                return <span>An error occured, please try again.</span>
        }
    }

    const renderView = () => {
        return (
            <div className={`alert alert-${props.content.success ? 'success' : 'danger'} ${props.content.success ? 'alert-dismissible' : ''}`}>
                {renderMessage()}
                {!props.content.success || <button type="button" className="close" aria-label="Close" onClick={props.handleAlertDismiss}>
                    <span aria-hidden="true">&times;</span>
                </button>}
            </div>
        )
    }

    return (
        props.content ? renderView() : null
    )
}
export default Alert