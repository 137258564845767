import React, { useRef, useEffect, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import DropdownIcon from "@material-ui/icons/ArrowDropDown";

function Dropdown(props) {
  const { options, multi, placeholder, value, handleChange } = props;

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [optionsSelected, setDropdownOptionsSelected] = useState([]);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (ref.current !== null && !ref.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    };
    if (isOpen) {
      window.addEventListener("click", pageClickEvent);
    }
    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isOpen]);

  useEffect(() => {
    setDropdownOptionsSelected(value);
  }, [value]);

  useEffect(() => {
    if (options) {
      let options_array = options.map((data) => {
        return {
          label: data.label,
          value: data.value,
          selected: false,
        };
      });

      value.forEach((value) => {
        let index = options_array.findIndex((data) => data.value === value);
        if (index !== -1) {
          options_array[index].selected = !options_array[index].selected;
        }
      });
      setDropdownOptions(options_array);
    }
  }, [options]);

  const toggleSelect = (index) => {
    let options_array = [...dropdownOptions];

    if (!multi) {
      let currentSelectedIndex = options_array.findIndex(
        (data) => data.selected
      );
      if (currentSelectedIndex !== -1 && currentSelectedIndex !== index) {
        options_array[currentSelectedIndex].selected =
          !dropdownOptions[currentSelectedIndex].selected;
      }
      setIsOpen(false);
    }

    options_array[index].selected = !dropdownOptions[index].selected;

    let selectedOptionsFilter = options_array
      .filter((data) => data.selected)
      .map((data) => data.value);

    setDropdownOptions(options_array);
    setDropdownOptionsSelected(selectedOptionsFilter);
    handleChange(selectedOptionsFilter);
  };

  const clearSelect = (e) => {
    e.stopPropagation();

    dropdownOptions.forEach((data) => {
      data.selected = false;
    });

    setDropdownOptionsSelected([]);
    handleChange([]);
    setIsOpen(false);
  };

  const renderSelected = () => {
    if (optionsSelected.length > 0) {
      if (optionsSelected.length > 3) {
        return optionsSelected.length + " Selected";
      } else {
        return optionsSelected.map((data, i) => (
          <span key={i}>
            {data}
            {i < optionsSelected.length - 1 ? ", " : null}
          </span>
        ));
      }
    } else {
      return placeholder || "";
    }
  };

  return (
    <div ref={ref} className="dropdown-multiselect">
      <div className="select-container" onClick={() => setIsOpen(!isOpen)}>
        <div className="input-container">{renderSelected()}</div>
        {optionsSelected.length <= 0 || (
          <button className="btn" onClick={(e) => clearSelect(e)}>
            <CloseIcon className="close" />
          </button>
        )}
        <DropdownIcon className="dropdown" />
      </div>

      <ul className={`list-group ${isOpen ? "open" : ""}`}>
        {dropdownOptions.map((data, i) => (
          <li
            key={i}
            className={`list-group-item ${data.selected ? "selected" : ""}`}
            onClick={() => toggleSelect(i)}
          >
            {data.label} <CheckIcon />
          </li>
        ))}
      </ul>
    </div>
  );
}
export default Dropdown;
