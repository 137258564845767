import React, { useEffect, useState } from 'react'

import * as ApiUser from "../../api/user";

import ErrorMessage from '../includes/ErrorMessage';
import Message from '../includes/Message';
import Alert from '../includes/Alert';

import * as Helper from '../../helpers/functions/general';

function ForgotPassword(props){

    const [isLoading, setIsLoading] =  useState(false)
    const [isCompleted, setIsCompleted] =  useState(false)

    const [errorForm, setErrorForm] = useState({})
    const [ApiResponse, setApiReponse] = useState(null)

    const [email, setEmail] =  useState('')

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch(name) {
            case 'email':
                setEmail(value)
            break;
            default:
            break;
        }
    }

    const validateForm = () => {
        let errors = {}

        if(email.length <= 0){
            errors.email = 'Email is required'
        }else{
            if(Helper.regexEmail(email)){
                errors.email = false
            }else{
                errors.email = 'Enter a valid email'
            }
        }

        setErrorForm(errors)

        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }

    const handleFormSubmit = () => {
        if(!validateForm()){
            setIsLoading(true)
            forgotPassword()
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        props.handleForm('Sign in')
    }

    const forgotPassword = () => {
        ApiUser.forgotPassword({
            email: email,
        })
        .then((res) => {
            setIsLoading(false)
            setIsCompleted(true)
        })
        .catch((error) => {
            setIsLoading(false)
            setApiReponse(error.response.data)
        })
    }

    const renderForm = () => {
        return (
            <div id="forgot-password-form">
                <h6>Enter your email address to reset your password. Please check your spam folder too!</h6>
                <div className="form-group">
                    <input 
                        className={`form-control ${!errorForm.email || 'error'}`} 
                        name="email" 
                        type="text"
                        placeholder="Email Address"
                        onChange={handleInputChange}
                    />  
                    <ErrorMessage
                        message={errorForm.email}
                    />
                </div>
                <div className="actions">
                    <button 
                        className="btn" 
                        disabled={isLoading}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn" 
                        disabled={isLoading}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </button>
                </div>
                <Alert
                    content={ApiResponse}
                />
            </div>
        )
    }


    return (
        !isCompleted ?
                renderForm()
        :
            <Message
                message={`If an account exists with ${email}, you should receive an email to reset your password. Please check your spam folder too!`}
                buttonText={'Back'}
                handleButton={handleCancel}
            /> 
    )   
}

export default ForgotPassword