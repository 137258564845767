import React, { useEffect } from 'react';

import { useSelector } from "react-redux";

import SignIn from './SignIn';
import Account from './Account';
import Contact from './Contact';
import AddRestaurant from './Restaurant/AddRestaurant';
import ViewRestaurant from './Restaurant/ViewRestaurant';

import $ from 'jquery';

function Modal(){

    const user_action = useSelector(state => state.user_action);

    useEffect(() => {
        if(user_action.action){
            $('#modal').modal('show')
        }else{
            $('#modal').modal('hide')
        }
    }, [user_action]);

    const modalContent = () => { 
        let action = user_action.action;
        switch (action) {
            case 'login_user':
                return <SignIn/>
            case 'account_user':
                return <Account/>
            case 'contact_user':
                return <Contact/>
            case 'add_restaurant_user':
                return <AddRestaurant/>
            case 'view_restaurant_user':
                return <ViewRestaurant/>
            default:
                break;
        }
    };

    return (
        <div id="modal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-dialog-centered" role="document">
                {modalContent()}
            </div>
        </div>
    );  
}

export default Modal