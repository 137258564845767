const INITIAL_STATE = {
  action: null,
  position: null,
  place: null,
  address: null,
};
export const restaurantAddReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "restaurantAdd":
      return {
        action: action.action || INITIAL_STATE.action,
        position: action.position || INITIAL_STATE.position,
        place: action.place || INITIAL_STATE.place,
        address: action.address || INITIAL_STATE.address,
      };
    default:
      return state;
  }
};
export default restaurantAddReducer;
