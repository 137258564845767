import axios from 'axios';

import store from '../redux/store';

export const authCheck = async () => {
    await axios.get(process.env.NODE_ENV === 'production' ?  '/auth' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/auth`)
    .then((res) => {
        store.dispatch({ type: "auth", payload: res.data })
    });
}

export const register = async (params) => {
    return axios.post(process.env.NODE_ENV === 'production' ? '/auth/register' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/auth/register`, params)
}

export const authLocal = async (params) => {
    return axios.post(process.env.NODE_ENV === 'production' ? '/auth/local' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/auth/local`, params)
}

export const authLogout = async () => {
    return axios.get(process.env.NODE_ENV === 'production' ? '/auth/logout' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/auth/logout`)
}