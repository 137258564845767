const INITIAL_STATE = {
  buttons: false,
  duration: null,
  color: "#ffa500",
  message: null,
};
export const snackbarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "snackbar":
      return {
        buttons: action.buttons || INITIAL_STATE.buttons,
        duration: action.duration || INITIAL_STATE.duration,
        color: action.color || INITIAL_STATE.color,
        message: action.message
          ? { key: new Date().getTime(), message: action.message }
          : INITIAL_STATE.message,
      };

    default:
      return state;
  }
};
export default snackbarReducer;
