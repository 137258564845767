import { createStore } from 'redux';
import { combineReducers } from 'redux';

//MAP
import mapReducer from './Reducers/Map/mapReducer';
import mapCircleReducer from './Reducers/Map/mapCircleReducer';
import mapZoomReducer from './Reducers/Map/mapZoomReducer';
import infoWindowReducer from './Reducers/Map/infoWindowReducer';
import polygonReducer from './Reducers/Map/polygonReducer';
import polygonArrayReducer from './Reducers/Map/polygonArrayReducer';

//RESTAURANT
import restaurantCountReducer from './Reducers/Restaurant/restaurantCountReducer';
import restaurantsReducer from './Reducers/Restaurant/restaurantsReducer';
import restaurantAddReducer from './Reducers/Restaurant/restaurantAddReducer';
import restaurantViewReducer from './Reducers/Restaurant/restaurantViewReducer';
import restaurantShareReducer from './Reducers/Restaurant/restaurantShareReducer';

//SEARCH
import isSearchReducer from './Reducers/Search/isSearchReducer';
import searchRestaurantReducer from './Reducers/Search/searchRestaurant';

import authReducer from './Reducers/authReducer';
import userActionReducer from './Reducers/userActionReducer';
import userLocationReducer from './Reducers/Map/userLocationReducer';
import snackbarReducer from './Reducers/snackbarReducer';
import optionReducer from './Reducers/optionReducer';

const store = createStore(
    combineReducers({

        map: mapReducer,
        mapCircle: mapCircleReducer,
        mapZoom: mapZoomReducer,
        infoWindow: infoWindowReducer,
        polygon: polygonReducer,
        polygonArray: polygonArrayReducer,

        restaurantCount: restaurantCountReducer,
        restaurants: restaurantsReducer,
        restaurantView: restaurantViewReducer,
        restaurantShare: restaurantShareReducer,
        restaurantAdd: restaurantAddReducer,

        isSearch: isSearchReducer,
        searchRestaurant: searchRestaurantReducer,

        auth: authReducer,
        user_action: userActionReducer,
        userLocation: userLocationReducer,
        snackbar: snackbarReducer,
        options: optionReducer
    }),
    
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store