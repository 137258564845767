import { useEffect, useState } from "react";

import { OverlayView as OverlayViewComponent } from "@react-google-maps/api";

import { useSelector } from "react-redux";

import * as MapHelpers from "../../../helpers/functions/map";

function OverlayView() {
  //REDUX
  const map = useSelector((state) => state.map);
  const mapZoom = useSelector((state) => state.mapZoom);
  const polygon = useSelector((state) => state.polygon);
  const restaurantCount = useSelector((state) => state.restaurantCount);

  return !polygon
    ? restaurantCount.map((data, i) => (
        <OverlayViewComponent
          key={i}
          position={data.position}
          mapPaneName={OverlayViewComponent.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={MapHelpers.getPixelPositionOffset}
        >
          <div
            className="restaurant-count"
            onClick={() => map.panTo(data.position)}
          >
            {data.count}
          </div>
        </OverlayViewComponent>
      ))
    : null;
}

export default OverlayView;
