import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SnackbarComponent from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

import $ from 'jquery'

function Snackbar() {
  const dispatch = useDispatch();
  const restaurantAdd = useSelector((state) => state.restaurantAdd);
  const snackbar = useSelector((state) => state.snackbar);

  const [open, setOpen] = React.useState(false);

  const snackbar_options = {
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
  };

  useEffect(() => {
    if (snackbar.message){
        setOpen(true)
    }else{
        setOpen(false)
    }
    
  }, [snackbar.message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false)

    if(restaurantAdd.action){
        switch (restaurantAdd.action) {
          case "add":
            dispatch({ type: 'restaurantAdd', action: null })
            $('.pac-container').remove()
            return;
          case "marked":
            dispatch({ type: "restaurantAdd", action: "add" });
            return;
          default:
            break;
        }
    }
  };

  const handleOnEnded = () => {
    dispatch({ type: 'snackbar', message: null })
  }

  return (
    <SnackbarComponent
      key={snackbar.message ? snackbar.message.key : null}
      anchorOrigin={snackbar_options.anchor}
      autoHideDuration={snackbar.duration}
      open={open}
      onClose={handleClose}
      onEnded={handleOnEnded}
    >
      <SnackbarContent
        style={{ backgroundColor: "#ffa500" }}
        className={`${!snackbar.buttons ? "no-action" : ""}`}
        message={snackbar.message ? snackbar.message.message : null}
        action={
          !snackbar.buttons || [
            restaurantAdd.action === "marked" ||
            restaurantAdd.action === "confirm" ||
            restaurantAdd.action === "get" ? (
              <IconButton
                key="confirm"
                aria-label="Confirm"
                color="inherit"
                onClick={() =>
                  dispatch({ type: "restaurantAdd", action: "get", position: restaurantAdd.position })
                }
              >
                <CheckIcon />
              </IconButton>
            ) : null,

            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]
        }
      />
    </SnackbarComponent>
  );
}
export default Snackbar;
