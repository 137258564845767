import axios from 'axios';


export const getRestaurantsCount = async (params) => {
    return await axios.get(process.env.NODE_ENV === 'production' ?  '/api/restaurants/count' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/count`, params);
}

export const getAllRestaurants = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants`, params);
}

export const getShareRestaurant = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants/share' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/share`, params);
}

export const getSingleRestaurant = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants/single' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/single`, params);
}

export const searchRestaurants = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants/search' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/search`, params);
}

export const insertRestaurant = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants/insert' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/insert`, params);
}

export const insertMeatRate = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ?  '/api/restaurants/insert/rate' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/restaurants/insert/rate`, params);
}