export const country_polygons = [
  {
    name: 'Egypt',
    code: 'EG',
    paths: [
      {
        "lat": 31.8148362793812,
        "lng": 25.4884860742936
      },
      {
        "lat": 31.9532720072515,
        "lng": 29.0656584562643
      },
      {
        "lat": 31.9478470763877,
        "lng": 31.4248659789582
      },
      {
        "lat": 31.3258601357862,
        "lng": 34.2142081345503
      },
      {
        "lat": 31.2915808726945,
        "lng": 34.237134884485
      },
      {
        "lat": 30.8563483967438,
        "lng": 34.397509747999
      },
      {
        "lat": 30.6778906704843,
        "lng": 34.4892167477378
      },
      {
        "lat": 30.523889441564,
        "lng": 34.5161893947198
      },
      {
        "lat": 30.494454354023,
        "lng": 34.5503547475637
      },
      {
        "lat": 30.4177263816577,
        "lng": 34.5341711593745
      },
      {
        "lat": 30.3696437701293,
        "lng": 34.6016027768296
      },
      {
        "lat": 30.0168355568413,
        "lng": 34.7391632764378
      },
      {
        "lat": 29.755695365657,
        "lng": 34.8395614624265
      },
      {
        "lat": 29.6619864675554,
        "lng": 34.8563444427708
      },
      {
        "lat": 29.6328147617152,
        "lng": 34.8731274231152
      },
      {
        "lat": 29.5952958655144,
        "lng": 34.8575432270811
      },
      {
        "lat": 29.524779929862,
        "lng": 34.8840163806005
      },
      {
        "lat": 29.468562971613,
        "lng": 34.9094281105119
      },
      {
        "lat": 27.9458918509365,
        "lng": 34.4706730529378
      },
      {
        "lat": 22.0130044204173,
        "lng": 37.1385383984182
      },
      {
        "lat": 22.0044834961075,
        "lng": 31.4055688815186
      },
      {
        "lat": 22.0932334044478,
        "lng": 31.4424314990607
      },
      {
        "lat": 22.1835926283747,
        "lng": 31.512560381214
      },
      {
        "lat": 22.2304843586401,
        "lng": 31.4737497391676
      },
      {
        "lat": 22.1852576916983,
        "lng": 31.4078166021005
      },
      {
        "lat": 22.1458459030464,
        "lng": 31.3706542884808
      },
      {
        "lat": 22.1000366530814,
        "lng": 31.3400852885678
      },
      {
        "lat": 22.005421285958,
        "lng": 31.3118389332561
      },
      {
        "lat": 22.0386565611391,
        "lng": 27.1119730260976
      },
      {
        "lat": 22.0174036101657,
        "lng": 25.018895620293
      },
      {
        "lat": 29.2652970822133,
        "lng": 25.0149919618142
      },
      {
        "lat": 29.4690874212011,
        "lng": 24.9064639343773
      },
      {
        "lat": 29.4953372367833,
        "lng": 24.8808609921249
      },
      {
        "lat": 29.5349467181012,
        "lng": 24.8694819066793
      },
      {
        "lat": 29.5770147991657,
        "lng": 24.8842747177585
      },
      {
        "lat": 29.6116459445984,
        "lng": 24.9195498826397
      },
      {
        "lat": 29.8083224528901,
        "lng": 24.8216897478081
      },
      {
        "lat": 29.8912259115286,
        "lng": 24.8307930161645
      },
      {
        "lat": 30.091291275386,
        "lng": 24.7556910522239
      },
      {
        "lat": 30.1523145202569,
        "lng": 24.7010714420853
      },
      {
        "lat": 30.2771958956749,
        "lng": 24.7701045604549
      },
      {
        "lat": 30.3169869141821,
        "lng": 24.7814836459005
      },
      {
        "lat": 30.3363034763089,
        "lng": 24.7972247141001
      },
      {
        "lat": 30.3534887389063,
        "lng": 24.8249138220176
      },
      {
        "lat": 30.3968476458623,
        "lng": 24.8632234096843
      },
      {
        "lat": 30.4365900356633,
        "lng": 24.8840850663344
      },
      {
        "lat": 30.4689607535541,
        "lng": 24.924291168242
      },
      {
        "lat": 30.4929866026026,
        "lng": 24.9320668766298
      },
      {
        "lat": 30.5210909499594,
        "lng": 24.9487562019499
      },
      {
        "lat": 30.5940915678464,
        "lng": 24.9523595790077
      },
      {
        "lat": 30.6169439990453,
        "lng": 24.9699971614483
      },
      {
        "lat": 30.6533335201167,
        "lng": 24.9868761381925
      },
      {
        "lat": 30.7291689613123,
        "lng": 24.9980655722139
      },
      {
        "lat": 30.7760266443546,
        "lng": 25.0197806602725
      },
      {
        "lat": 30.9168695080374,
        "lng": 24.9803331640613
      },
      {
        "lat": 31.0953521172811,
        "lng": 24.9056105029689
      },
      {
        "lat": 31.160614563277,
        "lng": 24.8798179092924
      },
      {
        "lat": 31.247236994881,
        "lng": 24.8930935089788
      },
      {
        "lat": 31.3237360482665,
        "lng": 24.8817144235333
      },
      {
        "lat": 31.3477102529987,
        "lng": 24.8654044010613
      },
      {
        "lat": 31.402439241178,
        "lng": 24.8619906754277
      },
      {
        "lat": 31.4675974273148,
        "lng": 24.9445522620492
      },
      {
        "lat": 31.5510285836552,
        "lng": 25.0777297065229
      },
      {
        "lat": 31.5787506042437,
        "lng": 25.0752010208684
      },
      {
        "lat": 31.6058183000902,
        "lng": 25.0827870778321
      },
      {
        "lat": 31.6259883096638,
        "lng": 25.0969477174976
      },
      {
        "lat": 31.6441447562335,
        "lng": 25.1190315722141
      },
      {
        "lat": 31.8148362793812,
        "lng": 25.4884860742936
      }
    ]
  },
  {
    name: 'Oman',
    code: 'OM',
    paths: [
      {
        "lat": 24.9983519246146,
        "lng": 56.4334504370539
      },
      {
        "lat": 24.4808378421134,
        "lng": 57.9029722633888
      },
      {
        "lat": 23.7509152815253,
        "lng": 59.6721245178343
      },
      {
        "lat": 22.4580083087603,
        "lng": 60.596416307912
      },
      {
        "lat": 18.9553386611227,
        "lng": 58.9307654778762
      },
      {
        "lat": 16.6892248812328,
        "lng": 55.7150002915643
      },
      {
        "lat": 16.2439645966992,
        "lng": 53.325242477279
      },
      {
        "lat": 18.9974694748356,
        "lng": 52.0061002222126
      },
      {
        "lat": 19.9868754860543,
        "lng": 55.016247625404
      },
      {
        "lat": 22.0079804281466,
        "lng": 55.6909234352471
      },
      {
        "lat": 22.7024568918542,
        "lng": 55.2291517189152
      },
      {
        "lat": 23.0973547192495,
        "lng": 55.2492912953284
      },
      {
        "lat": 23.37758508223,
        "lng": 55.4305474830475
      },
      {
        "lat": 23.617688764436,
        "lng": 55.5873484708362
      },
      {
        "lat": 23.7375761353631,
        "lng": 55.5772786826296
      },
      {
        "lat": 23.7652268155936,
        "lng": 55.5413151533202
      },
      {
        "lat": 23.8336698725531,
        "lng": 55.544192235665
      },
      {
        "lat": 23.907337407377,
        "lng": 55.5182984945623
      },
      {
        "lat": 23.9467849026043,
        "lng": 55.5053516240109
      },
      {
        "lat": 24.0400959091749,
        "lng": 55.7326411292459
      },
      {
        "lat": 24.015131987937,
        "lng": 55.8002525643475
      },
      {
        "lat": 24.0019911348984,
        "lng": 55.8491629642082
      },
      {
        "lat": 24.0420665385133,
        "lng": 55.9210900228268
      },
      {
        "lat": 24.0663684803865,
        "lng": 56.0433660224786
      },
      {
        "lat": 24.1609051641034,
        "lng": 55.9807894814803
      },
      {
        "lat": 24.2370088746258,
        "lng": 55.9671233403428
      },
      {
        "lat": 24.2107303476044,
        "lng": 55.8461959730401
      },
      {
        "lat": 24.2063842341377,
        "lng": 55.8248426275127
      },
      {
        "lat": 24.2106073464312,
        "lng": 55.8186838731185
      },
      {
        "lat": 24.2153633053288,
        "lng": 55.8070856349162
      },
      {
        "lat": 24.2264735173755,
        "lng": 55.7937341746601
      },
      {
        "lat": 24.2345698325168,
        "lng": 55.7779551761757
      },
      {
        "lat": 24.2351539771104,
        "lng": 55.7765278736062
      },
      {
        "lat": 24.2331376421038,
        "lng": 55.7685316076363
      },
      {
        "lat": 24.2323895147653,
        "lng": 55.7657275762105
      },
      {
        "lat": 24.2336167487405,
        "lng": 55.7596924464483
      },
      {
        "lat": 24.2352180278896,
        "lng": 55.7533201585988
      },
      {
        "lat": 24.2461010681809,
        "lng": 55.7541967696257
      },
      {
        "lat": 24.2606718168204,
        "lng": 55.7595912990221
      },
      {
        "lat": 24.2618091256836,
        "lng": 55.7683911251
      },
      {
        "lat": 24.2792670763885,
        "lng": 55.7921944860616
      },
      {
        "lat": 24.3101199792106,
        "lng": 55.8084117900595
      },
      {
        "lat": 24.32667070113,
        "lng": 55.834710120867
      },
      {
        "lat": 24.4106982448688,
        "lng": 55.8356541635114
      },
      {
        "lat": 24.4103298240643,
        "lng": 55.8283715488262
      },
      {
        "lat": 24.4408233843755,
        "lng": 55.821718295904
      },
      {
        "lat": 24.4864885297221,
        "lng": 55.7867437636507
      },
      {
        "lat": 24.5302558607012,
        "lng": 55.7661546431211
      },
      {
        "lat": 24.5720455562769,
        "lng": 55.7686720901727
      },
      {
        "lat": 24.615599137149,
        "lng": 55.8176723988567
      },
      {
        "lat": 24.6368696825491,
        "lng": 55.7957177880737
      },
      {
        "lat": 24.6710665969274,
        "lng": 55.8385368651576
      },
      {
        "lat": 24.719036783921,
        "lng": 55.8307147975328
      },
      {
        "lat": 24.7814989592401,
        "lng": 55.8361093269292
      },
      {
        "lat": 24.8003539128076,
        "lng": 55.8206000549146
      },
      {
        "lat": 24.80010906165,
        "lng": 55.8156775468404
      },
      {
        "lat": 24.9106101342817,
        "lng": 55.8131488611858
      },
      {
        "lat": 24.9652734311732,
        "lng": 55.8521243360748
      },
      {
        "lat": 24.9648455130015,
        "lng": 55.9114641594352
      },
      {
        "lat": 25.0053077597776,
        "lng": 55.9614309879694
      },
      {
        "lat": 24.9944702361449,
        "lng": 56.0069023753399
      },
      {
        "lat": 24.9734239693795,
        "lng": 56.0297616936572
      },
      {
        "lat": 24.9675148830006,
        "lng": 56.0455406921416
      },
      {
        "lat": 24.9467832927866,
        "lng": 56.0415996887215
      },
      {
        "lat": 24.9492653917919,
        "lng": 56.0591487671642
      },
      {
        "lat": 24.9086445624666,
        "lng": 56.0405573083017
      },
      {
        "lat": 24.8862582796176,
        "lng": 56.0421756671206
      },
      {
        "lat": 24.8763177136314,
        "lng": 56.0513126512857
      },
      {
        "lat": 24.8726471486313,
        "lng": 56.0574826442829
      },
      {
        "lat": 24.8950970647901,
        "lng": 55.979076531087
      },
      {
        "lat": 24.8770212261386,
        "lng": 55.9780144831121
      },
      {
        "lat": 24.8089148975156,
        "lng": 56.0365788428718
      },
      {
        "lat": 24.7416897540814,
        "lng": 56.0807296944005
      },
      {
        "lat": 24.7365912816015,
        "lng": 56.1135014604836
      },
      {
        "lat": 24.7851388533636,
        "lng": 56.2029410720855
      },
      {
        "lat": 24.8492772408708,
        "lng": 56.2066013445705
      },
      {
        "lat": 24.8592926370246,
        "lng": 56.2603959710143
      },
      {
        "lat": 24.8754755962467,
        "lng": 56.2810205633843
      },
      {
        "lat": 24.8838372519905,
        "lng": 56.2810205633843
      },
      {
        "lat": 24.8844695146718,
        "lng": 56.3070217736274
      },
      {
        "lat": 24.8994622240322,
        "lng": 56.3283006634105
      },
      {
        "lat": 24.917936104736,
        "lng": 56.3413866116729
      },
      {
        "lat": 24.935169018244,
        "lng": 56.351400206865
      },
      {
        "lat": 24.9428043892457,
        "lng": 56.3413866116729
      },
      {
        "lat": 24.9720001580947,
        "lng": 56.3240904017957
      },
      {
        "lat": 24.9718970050252,
        "lng": 56.3443451738887
      },
      {
        "lat": 24.9759714855055,
        "lng": 56.3511157297288
      },
      {
        "lat": 24.9772608499512,
        "lng": 56.3632913511555
      },
      {
        "lat": 24.9809741440367,
        "lng": 56.3774583125352
      },
      {
        "lat": 24.9983519246146,
        "lng": 56.4334504370539
      }
    ]
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    paths: [
      {
        "lat": 32.1499357358321,
        "lng": 39.2030485348897
      },
      {
        "lat": 31.9406061213894,
        "lng": 40.4046278619908
      },
      {
        "lat": 31.3627991469117,
        "lng": 41.4401235080623
      },
      {
        "lat": 31.1059459175634,
        "lng": 42.0806288344678
      },
      {
        "lat": 29.1968244591472,
        "lng": 44.719554105436
      },
      {
        "lat": 29.0592352697018,
        "lng": 46.4260187965802
      },
      {
        "lat": 29.0993483160164,
        "lng": 46.5531540491509
      },
      {
        "lat": 28.9994509019346,
        "lng": 47.4651700888918
      },
      {
        "lat": 28.9656959340125,
        "lng": 47.4969877506108
      },
      {
        "lat": 28.9306264860322,
        "lng": 47.5110687583502
      },
      {
        "lat": 28.8969676158644,
        "lng": 47.5419386599329
      },
      {
        "lat": 28.8614006139955,
        "lng": 47.5627893829317
      },
      {
        "lat": 28.8362592582994,
        "lng": 47.5855356262032
      },
      {
        "lat": 28.7586346880646,
        "lng": 47.5988869165753
      },
      {
        "lat": 28.7300127242201,
        "lng": 47.592314174957
      },
      {
        "lat": 28.6892753445629,
        "lng": 47.5940669060552
      },
      {
        "lat": 28.6552513291217,
        "lng": 47.6041998827168
      },
      {
        "lat": 28.6293414601292,
        "lng": 47.6386520033662
      },
      {
        "lat": 28.5957308223709,
        "lng": 47.6533858991606
      },
      {
        "lat": 28.541886534111,
        "lng": 47.7062599539569
      },
      {
        "lat": 28.5211624546406,
        "lng": 47.7008191845061
      },
      {
        "lat": 28.535984115419,
        "lng": 48.8366984818463
      },
      {
        "lat": 27.3657783889309,
        "lng": 50.1188943106565
      },
      {
        "lat": 26.8957381234522,
        "lng": 50.3385699416334
      },
      {
        "lat": 26.4952164239882,
        "lng": 50.3288325466432
      },
      {
        "lat": 26.1600865382023,
        "lng": 50.3335064962384
      },
      {
        "lat": 24.751532847056,
        "lng": 50.8059649011586
      },
      {
        "lat": 24.6489136159468,
        "lng": 50.8693228845609
      },
      {
        "lat": 24.544910886479,
        "lng": 50.9269682629024
      },
      {
        "lat": 24.5003424489599,
        "lng": 50.9971911097727
      },
      {
        "lat": 24.4699394363106,
        "lng": 51.0978027435081
      },
      {
        "lat": 24.4778122838495,
        "lng": 51.1553994348746
      },
      {
        "lat": 24.484259380385,
        "lng": 51.1723181586699
      },
      {
        "lat": 24.5049720419395,
        "lng": 51.3029371979664
      },
      {
        "lat": 24.517316789547,
        "lng": 51.3078708114281
      },
      {
        "lat": 24.5320816407751,
        "lng": 51.3195556854162
      },
      {
        "lat": 24.534207636352,
        "lng": 51.3408481224613
      },
      {
        "lat": 24.5588901703916,
        "lng": 51.3452624081901
      },
      {
        "lat": 24.572233249418,
        "lng": 51.3583321561324
      },
      {
        "lat": 24.5805768718312,
        "lng": 51.3791052654446
      },
      {
        "lat": 24.6290533344589,
        "lng": 51.3906170301885
      },
      {
        "lat": 24.6355049531105,
        "lng": 51.4214304756683
      },
      {
        "lat": 24.6436870146481,
        "lng": 51.4394338370722
      },
      {
        "lat": 24.5891167022267,
        "lng": 51.5344924508312
      },
      {
        "lat": 24.3924747260203,
        "lng": 51.4317304757023
      },
      {
        "lat": 24.271336090768,
        "lng": 51.5882212325211
      },
      {
        "lat": 24.1281764449406,
        "lng": 51.58452823531
      },
      {
        "lat": 22.9317032959788,
        "lng": 52.5742514878749
      },
      {
        "lat": 22.623551606573,
        "lng": 55.1464240453803
      },
      {
        "lat": 22.692846979024,
        "lng": 55.2067429998276
      },
      {
        "lat": 21.9960481307786,
        "lng": 55.6499026651552
      },
      {
        "lat": 19.995421448,
        "lng": 54.978380168
      },
      {
        "lat": 19.0291234698277,
        "lng": 52.0093020309164
      },
      {
        "lat": 18.8139495126265,
        "lng": 50.8221188337222
      },
      {
        "lat": 18.6280297321081,
        "lng": 49.0834095842881
      },
      {
        "lat": 18.1845474146393,
        "lng": 48.1517356316343
      },
      {
        "lat": 17.4577727485276,
        "lng": 47.5815137810135
      },
      {
        "lat": 17.1215791725381,
        "lng": 47.4545381503425
      },
      {
        "lat": 16.9651752769427,
        "lng": 47.1865650402146
      },
      {
        "lat": 16.9666654543597,
        "lng": 47.0144078967895
      },
      {
        "lat": 17.3023967453289,
        "lng": 46.7596776438482
      },
      {
        "lat": 17.2443758115151,
        "lng": 46.3701818442438
      },
      {
        "lat": 17.3470157849643,
        "lng": 45.4026742780264
      },
      {
        "lat": 17.4466257586185,
        "lng": 45.2079263782242
      },
      {
        "lat": 17.4436531128459,
        "lng": 44.6517263763891
      },
      {
        "lat": 17.413428464515,
        "lng": 44.5701919223386
      },
      {
        "lat": 17.4411758710155,
        "lng": 44.467365031243
      },
      {
        "lat": 17.4193746923868,
        "lng": 44.1339566267817
      },
      {
        "lat": 17.3727907301188,
        "lng": 44.1111062065382
      },
      {
        "lat": 17.4144195159365,
        "lng": 44.0695599879137
      },
      {
        "lat": 17.4074820430884,
        "lng": 44.0062020045114
      },
      {
        "lat": 17.3420586493062,
        "lng": 43.9656944413526
      },
      {
        "lat": 17.3437110427418,
        "lng": 43.8358625081511
      },
      {
        "lat": 17.3777470346557,
        "lng": 43.7894692306871
      },
      {
        "lat": 17.3698168830079,
        "lng": 43.6852574656374
      },
      {
        "lat": 17.4275065681625,
        "lng": 43.619302843571
      },
      {
        "lat": 17.471468162503,
        "lng": 43.5852706480806
      },
      {
        "lat": 17.5150709199285,
        "lng": 43.5274305218393
      },
      {
        "lat": 17.5099867921428,
        "lng": 43.5203830822152
      },
      {
        "lat": 17.5243682373967,
        "lng": 43.5066350976636
      },
      {
        "lat": 17.5277109766979,
        "lng": 43.4959270061104
      },
      {
        "lat": 17.5458860433923,
        "lng": 43.4846437996656
      },
      {
        "lat": 17.5536151171269,
        "lng": 43.4627346609378
      },
      {
        "lat": 17.5505861950893,
        "lng": 43.4456455327302
      },
      {
        "lat": 17.5643726031675,
        "lng": 43.4312402740167
      },
      {
        "lat": 17.5660697209869,
        "lng": 43.4193271798335
      },
      {
        "lat": 17.5568963921764,
        "lng": 43.3826293724645
      },
      {
        "lat": 17.5555212231323,
        "lng": 43.3791604254993
      },
      {
        "lat": 17.5441974406074,
        "lng": 43.3755271599936
      },
      {
        "lat": 17.556501829863,
        "lng": 43.3546982635226
      },
      {
        "lat": 17.5401093319453,
        "lng": 43.3359537781666
      },
      {
        "lat": 17.5277777147895,
        "lng": 43.3102318407131
      },
      {
        "lat": 17.5320459999929,
        "lng": 43.3046936973125
      },
      {
        "lat": 17.5275571888282,
        "lng": 43.3026610161083
      },
      {
        "lat": 17.5231408098514,
        "lng": 43.2941179734724
      },
      {
        "lat": 17.5172473009784,
        "lng": 43.2852402078838
      },
      {
        "lat": 17.4899855204307,
        "lng": 43.2603976789152
      },
      {
        "lat": 17.4805321856837,
        "lng": 43.2406448547516
      },
      {
        "lat": 17.4578205905235,
        "lng": 43.2563064327678
      },
      {
        "lat": 17.4320209304786,
        "lng": 43.2293398593828
      },
      {
        "lat": 17.4014867473151,
        "lng": 43.2468500512365
      },
      {
        "lat": 17.3912578349692,
        "lng": 43.2373717812595
      },
      {
        "lat": 17.3854771892799,
        "lng": 43.2277890890696
      },
      {
        "lat": 17.3717314388658,
        "lng": 43.2444373296708
      },
      {
        "lat": 17.3617796461113,
        "lng": 43.2587594148021
      },
      {
        "lat": 17.3577135830391,
        "lng": 43.276814168013
      },
      {
        "lat": 17.3281060535194,
        "lng": 43.3257750071977
      },
      {
        "lat": 17.3041033137191,
        "lng": 43.3362921545211
      },
      {
        "lat": 17.3045269898546,
        "lng": 43.3139899699017
      },
      {
        "lat": 17.3014280790109,
        "lng": 43.3097298595936
      },
      {
        "lat": 17.2994428118144,
        "lng": 43.3094255660001
      },
      {
        "lat": 17.2934384586988,
        "lng": 43.3022239509553
      },
      {
        "lat": 17.2904362086707,
        "lng": 43.2895450512286
      },
      {
        "lat": 17.2913562582385,
        "lng": 43.2721496008035
      },
      {
        "lat": 17.2923095942218,
        "lng": 43.2671794721106
      },
      {
        "lat": 17.2819073724196,
        "lng": 43.2573913615216
      },
      {
        "lat": 17.2742528746773,
        "lng": 43.2453242187067
      },
      {
        "lat": 17.2689953307875,
        "lng": 43.232087447392
      },
      {
        "lat": 17.2603868004148,
        "lng": 43.2239000478934
      },
      {
        "lat": 17.2597270121702,
        "lng": 43.2191970180531
      },
      {
        "lat": 17.2620553092178,
        "lng": 43.2084918416562
      },
      {
        "lat": 17.2595907709245,
        "lng": 43.2031971721328
      },
      {
        "lat": 17.2563820090777,
        "lng": 43.2019447748098
      },
      {
        "lat": 17.2494923566772,
        "lng": 43.2036507430552
      },
      {
        "lat": 17.2449192267083,
        "lng": 43.1987133640593
      },
      {
        "lat": 17.2103606338309,
        "lng": 43.21621984772
      },
      {
        "lat": 17.2073601069066,
        "lng": 43.2101722354217
      },
      {
        "lat": 17.2029282051319,
        "lng": 43.2050272518245
      },
      {
        "lat": 17.1890109988556,
        "lng": 43.1891409866826
      },
      {
        "lat": 17.1856393249487,
        "lng": 43.1838876876413
      },
      {
        "lat": 17.1841970748517,
        "lng": 43.1807939277678
      },
      {
        "lat": 17.1747542632464,
        "lng": 43.1518060070272
      },
      {
        "lat": 17.1609505081386,
        "lng": 43.1541785665684
      },
      {
        "lat": 17.1559482691075,
        "lng": 43.1555505621943
      },
      {
        "lat": 17.1314526012284,
        "lng": 43.158944446111
      },
      {
        "lat": 17.1139349622943,
        "lng": 43.1579083620848
      },
      {
        "lat": 17.1040831767265,
        "lng": 43.1575850501417
      },
      {
        "lat": 17.0865837633296,
        "lng": 43.160076453938
      },
      {
        "lat": 17.098448157261,
        "lng": 43.1852947854945
      },
      {
        "lat": 17.0779788784874,
        "lng": 43.2079519793061
      },
      {
        "lat": 17.0753291605713,
        "lng": 43.2272128128535
      },
      {
        "lat": 17.0584118926133,
        "lng": 43.2326282878992
      },
      {
        "lat": 17.0473389334555,
        "lng": 43.239598513024
      },
      {
        "lat": 17.0334558975449,
        "lng": 43.2425178296861
      },
      {
        "lat": 17.023936285114,
        "lng": 43.2397760176202
      },
      {
        "lat": 17.0293826174236,
        "lng": 43.2375160037439
      },
      {
        "lat": 17.0296190155695,
        "lng": 43.2308405630378
      },
      {
        "lat": 17.0221450527003,
        "lng": 43.2321908658586
      },
      {
        "lat": 17.0207195306151,
        "lng": 43.2274362784612
      },
      {
        "lat": 17.0114973224872,
        "lng": 43.2253780704055
      },
      {
        "lat": 17.0144717565082,
        "lng": 43.2212912383936
      },
      {
        "lat": 17.0220894868409,
        "lng": 43.2146136845375
      },
      {
        "lat": 17.0226956589617,
        "lng": 43.2115073541045
      },
      {
        "lat": 17.0210791956097,
        "lng": 43.2071753966979
      },
      {
        "lat": 17.0101108705135,
        "lng": 43.2027192917314
      },
      {
        "lat": 17.0139792201714,
        "lng": 43.1980439474572
      },
      {
        "lat": 17.0135738239067,
        "lng": 43.1957419347256
      },
      {
        "lat": 17.016136898606,
        "lng": 43.1910844201541
      },
      {
        "lat": 17.0180842814974,
        "lng": 43.1918451541377
      },
      {
        "lat": 17.0248810194434,
        "lng": 43.1904385886993
      },
      {
        "lat": 17.0258698219537,
        "lng": 43.179190027348
      },
      {
        "lat": 17.0166066971109,
        "lng": 43.1788988088699
      },
      {
        "lat": 17.013992480782,
        "lng": 43.1835345315825
      },
      {
        "lat": 17.009082201891,
        "lng": 43.1798972722234
      },
      {
        "lat": 17.0057404106942,
        "lng": 43.1793980405467
      },
      {
        "lat": 17.0028759707927,
        "lng": 43.1785778742206
      },
      {
        "lat": 16.9935321362852,
        "lng": 43.177864686111
      },
      {
        "lat": 16.9911406877261,
        "lng": 43.182620759317
      },
      {
        "lat": 16.9892735462579,
        "lng": 43.184773695923
      },
      {
        "lat": 16.9853026116714,
        "lng": 43.1846266008753
      },
      {
        "lat": 16.9811469481999,
        "lng": 43.1881978346165
      },
      {
        "lat": 16.9790199226855,
        "lng": 43.1832860301005
      },
      {
        "lat": 16.978768131147,
        "lng": 43.1811798964643
      },
      {
        "lat": 16.9768257279228,
        "lng": 43.1798719206148
      },
      {
        "lat": 16.9782965206896,
        "lng": 43.1776153488617
      },
      {
        "lat": 16.9744276715212,
        "lng": 43.1771473191647
      },
      {
        "lat": 16.967904803121,
        "lng": 43.177581918169
      },
      {
        "lat": 16.9591273505762,
        "lng": 43.1865580591426
      },
      {
        "lat": 16.9554739548321,
        "lng": 43.1948767298275
      },
      {
        "lat": 16.9504134447212,
        "lng": 43.1930324699503
      },
      {
        "lat": 16.9448491247051,
        "lng": 43.1938348065736
      },
      {
        "lat": 16.9372378916301,
        "lng": 43.1751804800811
      },
      {
        "lat": 16.931865071119,
        "lng": 43.1719042722025
      },
      {
        "lat": 16.9308416593441,
        "lng": 43.1638809059692
      },
      {
        "lat": 16.9282831055618,
        "lng": 43.1601366683936
      },
      {
        "lat": 16.9292425673055,
        "lng": 43.1542528664892
      },
      {
        "lat": 16.9255326214612,
        "lng": 43.146496945797
      },
      {
        "lat": 16.9256605518453,
        "lng": 43.144089935927
      },
      {
        "lat": 16.9188161542374,
        "lng": 43.141682926057
      },
      {
        "lat": 16.9177127117144,
        "lng": 43.138022265213
      },
      {
        "lat": 16.9071737104122,
        "lng": 43.1377381043256
      },
      {
        "lat": 16.8939951316614,
        "lng": 43.1432207379184
      },
      {
        "lat": 16.8885571002307,
        "lng": 43.1440230745417
      },
      {
        "lat": 16.877392650562,
        "lng": 43.1504083368357
      },
      {
        "lat": 16.8759850513063,
        "lng": 43.1567267377445
      },
      {
        "lat": 16.8651397871393,
        "lng": 43.1715699652761
      },
      {
        "lat": 16.8573653652434,
        "lng": 43.1748796038474
      },
      {
        "lat": 16.8485987612445,
        "lng": 43.1796601928947
      },
      {
        "lat": 16.8469029843791,
        "lng": 43.1873492522017
      },
      {
        "lat": 16.8469989721547,
        "lng": 43.1936676531104
      },
      {
        "lat": 16.8493218614741,
        "lng": 43.2021323044866
      },
      {
        "lat": 16.8446632557932,
        "lng": 43.2018381143914
      },
      {
        "lat": 16.8378671171066,
        "lng": 43.2209069814726
      },
      {
        "lat": 16.8228149133466,
        "lng": 43.2242233061823
      },
      {
        "lat": 16.8065326121823,
        "lng": 43.2324071397403
      },
      {
        "lat": 16.8051244858684,
        "lng": 43.2413264485364
      },
      {
        "lat": 16.8076463046823,
        "lng": 43.2461538405534
      },
      {
        "lat": 16.8007848308565,
        "lng": 43.2613818925026
      },
      {
        "lat": 16.7947082936537,
        "lng": 43.2663444930987
      },
      {
        "lat": 16.7870307925097,
        "lng": 43.2624457314327
      },
      {
        "lat": 16.7861431562533,
        "lng": 43.257156252953
      },
      {
        "lat": 16.7794971324971,
        "lng": 43.2543351977638
      },
      {
        "lat": 16.7741406930386,
        "lng": 43.2512605645801
      },
      {
        "lat": 16.7690648342072,
        "lng": 43.2512605645801
      },
      {
        "lat": 16.7603999011418,
        "lng": 43.2567785273986
      },
      {
        "lat": 16.7587761307172,
        "lng": 43.261432079814
      },
      {
        "lat": 16.7539910490877,
        "lng": 43.2650822161806
      },
      {
        "lat": 16.7522919762929,
        "lng": 43.2616998555347
      },
      {
        "lat": 16.7504556706664,
        "lng": 43.2591165297154
      },
      {
        "lat": 16.7488166391132,
        "lng": 43.2544887313151
      },
      {
        "lat": 16.7473748867302,
        "lng": 43.2519846486191
      },
      {
        "lat": 16.7427004986718,
        "lng": 43.2514259845999
      },
      {
        "lat": 16.7440019023268,
        "lng": 43.2476857091805
      },
      {
        "lat": 16.7414863531331,
        "lng": 43.239705926665
      },
      {
        "lat": 16.7444534076394,
        "lng": 43.2353990629141
      },
      {
        "lat": 16.7418733628639,
        "lng": 43.2329425260921
      },
      {
        "lat": 16.7461380062486,
        "lng": 43.2261434661136
      },
      {
        "lat": 16.7370622448526,
        "lng": 43.2238295669135
      },
      {
        "lat": 16.7303917398817,
        "lng": 43.229978833281
      },
      {
        "lat": 16.7189853185577,
        "lng": 43.2171414473077
      },
      {
        "lat": 16.7099689818883,
        "lng": 43.2112219859977
      },
      {
        "lat": 16.6995176950277,
        "lng": 43.2189957363927
      },
      {
        "lat": 16.7014303916003,
        "lng": 43.2274826748973
      },
      {
        "lat": 16.6921399716821,
        "lng": 43.2292656451713
      },
      {
        "lat": 16.6850352280978,
        "lng": 43.2328315857195
      },
      {
        "lat": 16.6760172886608,
        "lng": 43.2333308173962
      },
      {
        "lat": 16.6697318066819,
        "lng": 43.2345432371826
      },
      {
        "lat": 16.6668622788659,
        "lng": 43.2359696134018
      },
      {
        "lat": 16.6594832954997,
        "lng": 43.2342579619387
      },
      {
        "lat": 16.6434261917919,
        "lng": 43.2344005995607
      },
      {
        "lat": 16.6421278990768,
        "lng": 43.2271260808425
      },
      {
        "lat": 16.6480726410243,
        "lng": 43.2237740967272
      },
      {
        "lat": 16.6506008087167,
        "lng": 43.2154297958445
      },
      {
        "lat": 16.6484142872565,
        "lng": 43.2065149444742
      },
      {
        "lat": 16.6560670031822,
        "lng": 43.1887565605445
      },
      {
        "lat": 16.6599615715602,
        "lng": 43.1768463191137
      },
      {
        "lat": 16.6661107287562,
        "lng": 43.1659345410364
      },
      {
        "lat": 16.6669306014568,
        "lng": 43.1577328777756
      },
      {
        "lat": 16.6778619017801,
        "lng": 43.1515994600328
      },
      {
        "lat": 16.6738395974971,
        "lng": 43.1495312145149
      },
      {
        "lat": 16.6719437050035,
        "lng": 43.1445656423016
      },
      {
        "lat": 16.6627201762325,
        "lng": 43.1355794721203
      },
      {
        "lat": 16.6541111483974,
        "lng": 43.1402865136439
      },
      {
        "lat": 16.636208597832,
        "lng": 43.1454214680332
      },
      {
        "lat": 16.6224047267105,
        "lng": 43.1390027750465
      },
      {
        "lat": 16.6137938883802,
        "lng": 43.1409997017535
      },
      {
        "lat": 16.6053193530228,
        "lng": 43.1414276146193
      },
      {
        "lat": 16.5969811388993,
        "lng": 43.1394306879123
      },
      {
        "lat": 16.5931536407999,
        "lng": 43.1290181415118
      },
      {
        "lat": 16.5841313795596,
        "lng": 43.130444517731
      },
      {
        "lat": 16.5742029857129,
        "lng": 43.1294995434857
      },
      {
        "lat": 16.5749207181044,
        "lng": 43.1261653890732
      },
      {
        "lat": 16.5284847597617,
        "lng": 43.1205312030072
      },
      {
        "lat": 16.5289804547104,
        "lng": 43.0761709025883
      },
      {
        "lat": 16.5303478824534,
        "lng": 43.0667568195412
      },
      {
        "lat": 16.5256985886136,
        "lng": 43.0589830691463
      },
      {
        "lat": 16.5234422733172,
        "lng": 43.019187172629
      },
      {
        "lat": 16.5219380484751,
        "lng": 43.0011435134554
      },
      {
        "lat": 16.5219380484751,
        "lng": 42.9917294304083
      },
      {
        "lat": 16.5157159031063,
        "lng": 42.9832424919037
      },
      {
        "lat": 16.5124338116426,
        "lng": 42.9741136841005
      },
      {
        "lat": 16.502518821893,
        "lng": 42.9565692566037
      },
      {
        "lat": 16.4969285949412,
        "lng": 42.9497048210485
      },
      {
        "lat": 16.4896798644372,
        "lng": 42.9444628884427
      },
      {
        "lat": 16.4501660944081,
        "lng": 42.9374558152656
      },
      {
        "lat": 16.4351347277493,
        "lng": 42.9462012844599
      },
      {
        "lat": 16.4084486219286,
        "lng": 42.9381846043651
      },
      {
        "lat": 16.4073390265742,
        "lng": 42.9499054052043
      },
      {
        "lat": 16.4021437271693,
        "lng": 42.9561034556196
      },
      {
        "lat": 16.3999501146718,
        "lng": 42.9500257556979
      },
      {
        "lat": 16.3986801171911,
        "lng": 42.9412401696724
      },
      {
        "lat": 16.3991419354154,
        "lng": 42.9316121301924
      },
      {
        "lat": 16.395043260396,
        "lng": 42.9231875956474
      },
      {
        "lat": 16.3798023600674,
        "lng": 42.8308185918864
      },
      {
        "lat": 16.3884043784034,
        "lng": 42.8274487780684
      },
      {
        "lat": 16.3955628155256,
        "lng": 42.810810322342
      },
      {
        "lat": 16.4014077152053,
        "lng": 42.8036494679788
      },
      {
        "lat": 16.4033704075913,
        "lng": 42.7934798512781
      },
      {
        "lat": 16.4047125314313,
        "lng": 42.7809784437658
      },
      {
        "lat": 16.2879565260106,
        "lng": 41.4201302823294
      },
      {
        "lat": 21.1501038815245,
        "lng": 38.2544309013128
      },
      {
        "lat": 27.8993938209785,
        "lng": 34.739233687173
      },
      {
        "lat": 27.9619167285256,
        "lng": 34.743566304939
      },
      {
        "lat": 27.9657434862462,
        "lng": 34.6164761838033
      },
      {
        "lat": 28.0307775957993,
        "lng": 34.507799688173
      },
      {
        "lat": 29.3646773852984,
        "lng": 34.8825711249312
      },
      {
        "lat": 29.1823076615226,
        "lng": 36.0699886534531
      },
      {
        "lat": 29.4897136626848,
        "lng": 36.5058580240778
      },
      {
        "lat": 29.865444268343,
        "lng": 36.7543149317694
      },
      {
        "lat": 29.9966291076031,
        "lng": 37.501557773632
      },
      {
        "lat": 30.330065519713,
        "lng": 37.6684437912849
      },
      {
        "lat": 30.50062308874,
        "lng": 38.0023049751044
      },
      {
        "lat": 30.795249752286,
        "lng": 37.7215763054521
      },
      {
        "lat": 30.996517128438,
        "lng": 37.5187634367767
      },
      {
        "lat": 31.2509919353476,
        "lng": 37.2588063708174
      },
      {
        "lat": 31.4999770083153,
        "lng": 37.0015371325462
      },
      {
        "lat": 31.9926771798239,
        "lng": 38.9958049850832
      },
      {
        "lat": 32.1499357358321,
        "lng": 39.2030485348897
      }
    ]
  },
  {
    name: 'Jordan',
    code: 'JO',
    paths: [
      {
        "lat": 32.2973619679658,
        "lng": 39.0371673114844
      },
      {
        "lat": 32.3509448276118,
        "lng": 39.2566597736937
      },
      {
        "lat": 32.232287206069,
        "lng": 39.2956806558642
      },
      {
        "lat": 32.0032394480901,
        "lng": 38.9916429489521
      },
      {
        "lat": 31.5055434100857,
        "lng": 36.9864031707444
      },
      {
        "lat": 31.007255758,
        "lng": 37.480016724
      },
      {
        "lat": 30.5042846971189,
        "lng": 37.978183925912
      },
      {
        "lat": 30.330862936,
        "lng": 37.647551718
      },
      {
        "lat": 30.0121444264158,
        "lng": 37.4896777083222
      },
      {
        "lat": 29.8837691539241,
        "lng": 36.7525488560696
      },
      {
        "lat": 29.49460907,
        "lng": 36.477080526
      },
      {
        "lat": 29.1939214633616,
        "lng": 36.0675494635916
      },
      {
        "lat": 29.3565618315935,
        "lng": 34.9600239058338
      },
      {
        "lat": 29.359767971,
        "lng": 34.962412957
      },
      {
        "lat": 29.450832424,
        "lng": 34.969004754
      },
      {
        "lat": 29.517971096,
        "lng": 34.997813347
      },
      {
        "lat": 29.533880927,
        "lng": 34.996836785
      },
      {
        "lat": 29.5589870928,
        "lng": 34.9555802768
      },
      {
        "lat": 29.586205547,
        "lng": 34.959860474
      },
      {
        "lat": 29.651963807,
        "lng": 34.989832804
      },
      {
        "lat": 29.733095805,
        "lng": 35.002545207
      },
      {
        "lat": 29.842313945,
        "lng": 35.048950642
      },
      {
        "lat": 29.923394267,
        "lng": 35.054118286
      },
      {
        "lat": 30.034033509,
        "lng": 35.086261027
      },
      {
        "lat": 30.089740702,
        "lng": 35.129049113
      },
      {
        "lat": 30.12338206,
        "lng": 35.145275512
      },
      {
        "lat": 30.154904684,
        "lng": 35.145275512
      },
      {
        "lat": 30.216089579,
        "lng": 35.124811645
      },
      {
        "lat": 30.244666647,
        "lng": 35.125225057
      },
      {
        "lat": 30.313964743,
        "lng": 35.141761515
      },
      {
        "lat": 30.361403707,
        "lng": 35.162122029
      },
      {
        "lat": 30.406155497,
        "lng": 35.140004517
      },
      {
        "lat": 30.430185038,
        "lng": 35.140004517
      },
      {
        "lat": 30.617098694,
        "lng": 35.205323527
      },
      {
        "lat": 30.719779765,
        "lng": 35.263821249
      },
      {
        "lat": 30.780241191,
        "lng": 35.279530884
      },
      {
        "lat": 30.822822571,
        "lng": 35.316634562
      },
      {
        "lat": 30.889950256,
        "lng": 35.322215617
      },
      {
        "lat": 30.912584534,
        "lng": 35.33492802
      },
      {
        "lat": 30.963279114,
        "lng": 35.385260864
      },
      {
        "lat": 31.023947246,
        "lng": 35.391565389
      },
      {
        "lat": 31.103735657,
        "lng": 35.43848759
      },
      {
        "lat": 31.132209371,
        "lng": 35.443241821
      },
      {
        "lat": 31.159546204,
        "lng": 35.436213827
      },
      {
        "lat": 31.204608053,
        "lng": 35.410685669
      },
      {
        "lat": 31.257679749,
        "lng": 35.395699504
      },
      {
        "lat": 31.30299998,
        "lng": 35.42226119
      },
      {
        "lat": 31.324600728,
        "lng": 35.423914835
      },
      {
        "lat": 31.331835429,
        "lng": 35.416473429
      },
      {
        "lat": 31.400823466,
        "lng": 35.452853637
      },
      {
        "lat": 31.568565165,
        "lng": 35.464222453
      },
      {
        "lat": 31.641118876,
        "lng": 35.480138794
      },
      {
        "lat": 31.73506663,
        "lng": 35.527577758
      },
      {
        "lat": 31.765349019,
        "lng": 35.559410441
      },
      {
        "lat": 31.819299215,
        "lng": 35.538326456
      },
      {
        "lat": 31.839194641,
        "lng": 35.549075154
      },
      {
        "lat": 31.919241435,
        "lng": 35.524683879
      },
      {
        "lat": 31.944562887,
        "lng": 35.545871216
      },
      {
        "lat": 32.011690572,
        "lng": 35.524683879
      },
      {
        "lat": 32.057837627,
        "lng": 35.522823527
      },
      {
        "lat": 32.075097555,
        "lng": 35.528301229
      },
      {
        "lat": 32.086828105,
        "lng": 35.545147746
      },
      {
        "lat": 32.110805969,
        "lng": 35.535225871
      },
      {
        "lat": 32.135197245,
        "lng": 35.551969035
      },
      {
        "lat": 32.147031149,
        "lng": 35.546698038
      },
      {
        "lat": 32.155092672,
        "lng": 35.559410441
      },
      {
        "lat": 32.17439382,
        "lng": 35.555172973
      },
      {
        "lat": 32.237594096,
        "lng": 35.572536255
      },
      {
        "lat": 32.237594096,
        "lng": 35.559410441
      },
      {
        "lat": 32.251908468,
        "lng": 35.572536255
      },
      {
        "lat": 32.282630107,
        "lng": 35.560857381
      },
      {
        "lat": 32.313351745,
        "lng": 35.565611613
      },
      {
        "lat": 32.32820872,
        "lng": 35.55651656
      },
      {
        "lat": 32.374820862,
        "lng": 35.551969035
      },
      {
        "lat": 32.367947896,
        "lng": 35.565611613
      },
      {
        "lat": 32.409573263,
        "lng": 35.545147746
      },
      {
        "lat": 32.413965759,
        "lng": 35.559100383
      },
      {
        "lat": 32.429442851,
        "lng": 35.551969035
      },
      {
        "lat": 32.429442851,
        "lng": 35.559410441
      },
      {
        "lat": 32.436212464,
        "lng": 35.551969035
      },
      {
        "lat": 32.456728007,
        "lng": 35.572536255
      },
      {
        "lat": 32.477088522,
        "lng": 35.561270792
      },
      {
        "lat": 32.491480408,
        "lng": 35.579977661
      },
      {
        "lat": 32.519178976,
        "lng": 35.561374145
      },
      {
        "lat": 32.518817241,
        "lng": 35.551969035
      },
      {
        "lat": 32.525586853,
        "lng": 35.551969035
      },
      {
        "lat": 32.525586853,
        "lng": 35.565611613
      },
      {
        "lat": 32.552949524,
        "lng": 35.559410441
      },
      {
        "lat": 32.546102397,
        "lng": 35.565611613
      },
      {
        "lat": 32.56039093,
        "lng": 35.572536255
      },
      {
        "lat": 32.554396464,
        "lng": 35.574396606
      },
      {
        "lat": 32.56039093,
        "lng": 35.579977661
      },
      {
        "lat": 32.607545675,
        "lng": 35.565611613
      },
      {
        "lat": 32.62123993,
        "lng": 35.572536255
      },
      {
        "lat": 32.625477397,
        "lng": 35.56406132
      },
      {
        "lat": 32.640902812,
        "lng": 35.560547323
      },
      {
        "lat": 32.670358378,
        "lng": 35.593826945
      },
      {
        "lat": 32.681546326,
        "lng": 35.612223755
      },
      {
        "lat": 32.679143372,
        "lng": 35.635994913
      },
      {
        "lat": 32.686171366,
        "lng": 35.652014608
      },
      {
        "lat": 32.740534973,
        "lng": 35.740174601
      },
      {
        "lat": 32.748053894,
        "lng": 35.76973352
      },
      {
        "lat": 32.734411317,
        "lng": 35.788233684
      },
      {
        "lat": 32.713275656,
        "lng": 35.895720663
      },
      {
        "lat": 32.693767802,
        "lng": 35.922489054
      },
      {
        "lat": 32.690770569,
        "lng": 35.944193156
      },
      {
        "lat": 32.677618917,
        "lng": 35.944399862
      },
      {
        "lat": 32.674001567,
        "lng": 35.937475219
      },
      {
        "lat": 32.657439271,
        "lng": 35.955355265
      },
      {
        "lat": 32.655087992,
        "lng": 36.003621053
      },
      {
        "lat": 32.60790741,
        "lng": 36.005998169
      },
      {
        "lat": 32.5116172174483,
        "lng": 36.0772030672536
      },
      {
        "lat": 32.5230991766367,
        "lng": 36.1286212088637
      },
      {
        "lat": 32.5198432476654,
        "lng": 36.1554480653559
      },
      {
        "lat": 32.5104175251425,
        "lng": 36.1662194547051
      },
      {
        "lat": 32.5287539307705,
        "lng": 36.1975174539461
      },
      {
        "lat": 32.4977340844774,
        "lng": 36.2353189335487
      },
      {
        "lat": 32.4658461871014,
        "lng": 36.2920211529528
      },
      {
        "lat": 32.43703450654,
        "lng": 36.3281967624651
      },
      {
        "lat": 32.379316712,
        "lng": 36.387887004
      },
      {
        "lat": 32.369394837,
        "lng": 36.463954712
      },
      {
        "lat": 32.3596433243649,
        "lng": 36.4987098881998
      },
      {
        "lat": 32.3397271101165,
        "lng": 36.6519481442237
      },
      {
        "lat": 32.3309694559311,
        "lng": 36.6999113118916
      },
      {
        "lat": 32.3201500074059,
        "lng": 36.7106827012408
      },
      {
        "lat": 32.327795309,
        "lng": 36.728641398
      },
      {
        "lat": 32.3086421913001,
        "lng": 36.8387199708628
      },
      {
        "lat": 32.5859668603744,
        "lng": 37.3411138288084
      },
      {
        "lat": 33.3661291264803,
        "lng": 38.7889511443441
      },
      {
        "lat": 32.5119599837378,
        "lng": 39.07022666999
      },
      {
        "lat": 32.4822487210711,
        "lng": 38.9791779449254
      },
      {
        "lat": 32.2973619679658,
        "lng": 39.0371673114844
      }
    ]
  },
  {
    name: 'Qatar',
    code: 'QA',
    paths: [
      {
        "lat": 25.5797925065814,
        "lng": 50.7673703303903
      },
      {
        "lat": 25.6187825956576,
        "lng": 50.7793473262281
      },
      {
        "lat": 25.6330055429933,
        "lng": 50.8199522633369
      },
      {
        "lat": 26.2989725294493,
        "lng": 50.871584800272
      },
      {
        "lat": 26.2787570794235,
        "lng": 51.7987146352636
      },
      {
        "lat": 24.9399299572393,
        "lng": 51.7626294723294
      },
      {
        "lat": 24.6784806016886,
        "lng": 51.5336059421621
      },
      {
        "lat": 24.6394546840421,
        "lng": 51.4246444922227
      },
      {
        "lat": 24.630335637591,
        "lng": 51.3871579654285
      },
      {
        "lat": 24.5814890901982,
        "lng": 51.3772265031083
      },
      {
        "lat": 24.5718205929899,
        "lng": 51.3552772568874
      },
      {
        "lat": 24.5593440103531,
        "lng": 51.3437881983186
      },
      {
        "lat": 24.5353187602969,
        "lng": 51.3167374671364
      },
      {
        "lat": 24.506196332528,
        "lng": 51.2993609805449
      },
      {
        "lat": 24.4866389148788,
        "lng": 51.1715523489043
      },
      {
        "lat": 24.473737677114,
        "lng": 51.0993027467604
      },
      {
        "lat": 24.5014112880291,
        "lng": 51.0014456906921
      },
      {
        "lat": 24.5453020378766,
        "lng": 50.9312538303815
      },
      {
        "lat": 24.5841876459462,
        "lng": 50.9104477740679
      },
      {
        "lat": 24.6891392309943,
        "lng": 50.8507732609048
      },
      {
        "lat": 24.7455527328273,
        "lng": 50.8116998210828
      },
      {
        "lat": 25.2864345662503,
        "lng": 50.6432185442665
      },
      {
        "lat": 25.5619126712973,
        "lng": 50.7312214648188
      },
      {
        "lat": 25.5724670219659,
        "lng": 50.7347271689647
      },
      {
        "lat": 25.5797925065814,
        "lng": 50.7673703303903
      }
    ]
  },
  {
    name: 'Kuwait',
    code: 'KW',
    paths: [
      {
        "lat": 29.8122634770034,
        "lng": 48.4713250497058
      },
      {
        "lat": 28.5389603159343,
        "lng": 48.5129361842969
      },
      {
        "lat": 28.5342839008682,
        "lng": 48.1246088720913
      },
      {
        "lat": 28.5332573429516,
        "lng": 47.7218702153004
      },
      {
        "lat": 28.8379283699965,
        "lng": 47.5916569007952
      },
      {
        "lat": 29.0039993501099,
        "lng": 47.4715948205671
      },
      {
        "lat": 29.1016763721814,
        "lng": 46.5568882786837
      },
      {
        "lat": 29.1189995870486,
        "lng": 46.567733302354
      },
      {
        "lat": 29.1572725869226,
        "lng": 46.6087033917748
      },
      {
        "lat": 29.1683690904443,
        "lng": 46.628969345098
      },
      {
        "lat": 29.2027032546061,
        "lng": 46.6691726146634
      },
      {
        "lat": 29.3254898941102,
        "lng": 46.7704217680538
      },
      {
        "lat": 29.4322257607015,
        "lng": 46.8701889624905
      },
      {
        "lat": 29.6586604680266,
        "lng": 47.0042728915043
      },
      {
        "lat": 29.8443073111519,
        "lng": 47.0884039842188
      },
      {
        "lat": 30.0007738142266,
        "lng": 47.1664005180896
      },
      {
        "lat": 30.0880145350492,
        "lng": 47.3627064010902
      },
      {
        "lat": 30.0963552722884,
        "lng": 47.7097471585378
      },
      {
        "lat": 29.9939430389655,
        "lng": 47.967398629976
      },
      {
        "lat": 29.986732266202,
        "lng": 48.0164751007262
      },
      {
        "lat": 30.0181331862406,
        "lng": 48.0481160034736
      },
      {
        "lat": 30.0250572070399,
        "lng": 48.1071789350591
      },
      {
        "lat": 30.0136751069011,
        "lng": 48.1803554584097
      },
      {
        "lat": 29.8122634770034,
        "lng": 48.4713250497058
      }
    ]
  },
  {
    name: 'Bahrain',
    code: 'BH',
    paths: [
      {
        "lat": 26.3809234141951,
        "lng": 50.412109588672
      },
      {
        "lat": 26.3767361349878,
        "lng": 50.8275717749167
      },
      {
        "lat": 25.7769831313049,
        "lng": 50.6703534183055
      },
      {
        "lat": 25.7811919180638,
        "lng": 50.481837451297
      },
      {
        "lat": 26.1605344598769,
        "lng": 50.3460332491683
      },
      {
        "lat": 26.3809234141951,
        "lng": 50.412109588672
      }
    ]
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    paths: [
      {
        lng: 51.5717873790801,
        lat: 24.4027231634463
      },
      {
        lng: 56.0865998964901,
        lat: 26.050550727332
      },
      {
        lng: 56.0961954785064,
        lat: 26.052082488781
      },
      {
        lng: 56.1058145544276,
        lat: 26.0543981846155
      },
      {
        lng: 56.107049662571,
        lat: 26.0556766220868
      },
      {
        lng: 56.1352624860861,
        lat: 26.0576424940033
      },
      {
        lng: 56.1424381958974,
        lat: 26.0678934388514
      },
      {
        lng: 56.1534199183029,
        lat: 26.0694852698159
      },
      {
        lng: 56.1637169611943,
        lat: 26.0542233029333
      },
      {
        lng: 56.1612702388123,
        lat: 26.0494199850729
      },
      {
        lng: 56.1724085872508,
        lat: 26.0256242936269
      },
      {
        lng: 56.1719633218148,
        lat: 26.0231203672478
      },
      {
        lng: 56.1821126887323,
        lat: 26.0192115616764
      },
      {
        lng: 56.1801190630878,
        lat: 25.9957559950348
      },
      {
        lng: 56.1888185204457,
        lat: 25.9841893735634
      },
      {
        lng: 56.19189957826,
        lat: 25.9722957450494
      },
      {
        lng: 56.1803003017828,
        lat: 25.9532308455285
      },
      {
        lng: 56.170875889645,
        lat: 25.9470381624299
      },
      {
        lng: 56.1661636835762,
        lat: 25.9480159761528
      },
      {
        lng: 56.1656199674913,
        lat: 25.9395413208063
      },
      {
        lng: 56.1730507539845,
        lat: 25.9287841493684
      },
      {
        lng: 56.1734132313744,
        lat: 25.9190040504265
      },
      {
        lng: 56.1797565856979,
        lat: 25.9175369655988
      },
      {
        lng: 56.1786691535282,
        lat: 25.8929198204497
      },
      {
        lng: 56.166888638356,
        lat: 25.8850934269672
      },
      {
        lng: 56.1600015679476,
        lat: 25.8756358426735
      },
      {
        lng: 56.1643512966266,
        lat: 25.865199008755
      },
      {
        lng: 56.1632638644569,
        lat: 25.8573707783701
      },
      {
        lng: 56.1527520201494,
        lat: 25.8484002937471
      },
      {
        lng: 56.1511208718948,
        lat: 25.8407340676881
      },
      {
        lng: 56.1415152210621,
        lat: 25.828010298331
      },
      {
        lng: 56.1415152210621,
        lat: 25.8126747076158
      },
      {
        lng: 56.1551081231838,
        lat: 25.7978266590318
      },
      {
        lng: 56.1567392714384,
        lat: 25.7612696929758
      },
      {
        lng: 56.1603640453376,
        lat: 25.7563728155089
      },
      {
        lng: 56.1663449222711,
        lat: 25.731395599903
      },
      {
        lng: 56.1587328970829,
        lat: 25.6894285966489
      },
      {
        lng: 56.1772192439685,
        lat: 25.6177075654292
      },
      {
        lng: 56.1947993973793,
        lat: 25.6194507286075
      },
      {
        lng: 56.2029249322032,
        lat: 25.6191647426425
      },
      {
        lng: 56.2072142479838,
        lat: 25.6158962829997
      },
      {
        lng: 56.2155965376256,
        lat: 25.6159916143383
      },
      {
        lng: 56.22837386562,
        lat: 25.6099038748069
      },
      {
        lng: 56.2533797711158,
        lat: 25.6022948994008
      },
      {
        lng: 56.266330786193,
        lat: 25.6064263244166
      },
      {
        lng: 56.2670658097892,
        lat: 25.607919958323
      },
      {
        lng: 56.2613366532653,
        lat: 25.6105349077015
      },
      {
        lng: 56.2599396049916,
        lat: 25.6164728094585
      },
      {
        lng: 56.2593631652535,
        lat: 25.6183249386299
      },
      {
        lng: 56.2603801157085,
        lat: 25.6186154660912
      },
      {
        lng: 56.2619055413911,
        lat: 25.6199954618854
      },
      {
        lng: 56.262607841334,
        lat: 25.6197957266386
      },
      {
        lng: 56.262580152089,
        lat: 25.6203676948635
      },
      {
        lng: 56.2632925208483,
        lat: 25.6203676948636
      },
      {
        lng: 56.2634334842777,
        lat: 25.6201498025294
      },
      {
        lng: 56.2642062659354,
        lat: 25.6206854538054
      },
      {
        lng: 56.2638236509127,
        lat: 25.6213118903868
      },
      {
        lng: 56.2642868164665,
        lat: 25.6216024105851
      },
      {
        lng: 56.2641357842207,
        lat: 25.6220018747041
      },
      {
        lng: 56.2645385368762,
        lat: 25.6222379210558
      },
      {
        lng: 56.2643371605484,
        lat: 25.6229823718789
      },
      {
        lng: 56.2631591090312,
        lat: 25.6227009336985
      },
      {
        lng: 56.2635115176048,
        lat: 25.6241898249351
      },
      {
        lng: 56.2667612280935,
        lat: 25.625247471156
      },
      {
        lng: 56.2670028796868,
        lat: 25.6249705775567
      },
      {
        lng: 56.2707384105661,
        lat: 25.6258784230257
      },
      {
        lng: 56.3873906828117,
        lat: 25.6347567879592
      },
      {
        lng: 56.3759021633147,
        lat: 24.98135263332
      },
      {
        lng: 56.3434000240191,
        lat: 24.9722072085271
      },
      {
        lng: 56.3232221159806,
        lat: 24.9723441210972
      },
      {
        lng: 56.3495621396477,
        lat: 24.9335643950951
      },
      {
        lng: 56.3060648528581,
        lat: 24.8846889471995
      },
      {
        lng: 56.2803289581742,
        lat: 24.8844697311927
      },
      {
        lng: 56.2803289581742,
        lat: 24.8754815399714
      },
      {
        lng: 56.2600302243391,
        lat: 24.8601343366805
      },
      {
        lng: 56.2057794416487,
        lat: 24.8509251002637
      },
      {
        lng: 56.2014297129698,
        lat: 24.7851249128203
      },
      {
        lng: 56.1139518362041,
        lat: 24.7379466324154
      },
      {
        lng: 56.0816913485018,
        lat: 24.7434333994386
      },
      {
        lng: 55.9784457052748,
        lat: 24.8776738420261
      },
      {
        lng: 55.9802580922244,
        lat: 24.8951012593377
      },
      {
        lng: 55.8391799901822,
        lat: 24.9484821859874
      },
      {
        lng: 55.8344424256128,
        lat: 24.3274309126272
      },
      {
        lng: 55.8080813320156,
        lat: 24.3101169440966
      },
      {
        lng: 55.7921323268594,
        lat: 24.2797223244581
      },
      {
        lng: 55.7682088191252,
        lat: 24.2622090773356
      },
      {
        lng: 55.7594409311071,
        lat: 24.2610507572707
      },
      {
        lng: 55.7539276947568,
        lat: 24.2463734827937
      },
      {
        lng: 55.7530088220318,
        lat: 24.2348992226458
      },
      {
        lng: 55.7649465226159,
        lat: 24.232464073664
      },
      {
        lng: 55.7776332312629,
        lat: 24.2347778233628
      },
      {
        lng: 55.8102561963551,
        lat: 24.2116384342234
      },
      {
        lng: 55.8707899204706,
        lat: 24.2156054852205
      },
      {
        lng: 55.9630404162035,
        lat: 24.2232086543404
      },
      {
        lng: 56.0232116629291,
        lat: 24.0674186001467
      },
      {
        lng: 55.8214185980764,
        lat: 24.0051400359927
      },
      {
        lng: 55.7451915048657,
        lat: 24.0535172808772
      },
      {
        lng: 55.5017940907034,
        lat: 23.9433803859078
      },
      {
        lng: 55.5762585816684,
        lat: 23.7694574699625
      },
      {
        lng: 55.5728335059619,
        lat: 23.6248217866303
      },
      {
        lng: 55.233584875452,
        lat: 23.1024611334171
      },
      {
        lng: 55.231007258457,
        lat: 22.7154501966911
      },
      {
        lng: 55.1378757412888,
        lat: 22.6314997625838
      },
      {
        lng: 52.5820073897405,
        lat: 22.9389053039886
      },
      {
        lng: 51.5905914530605,
        lat: 24.1269939293024
      },
      {
        lng: 51.5717873790801,
        lat: 24.4027231634463
      }
    ]
  }
]