import axios from 'axios';

export const getUser = async () => {
    return axios.get('/user')
}
export const updateUser = async (params) => {
    return axios.post('/user', params)
}

export const forgotPassword = async (params) => {
    return axios.post('/user/forgot-password', params)
}

export const forgotPasswordVerify = async (params) => {
    return axios.post('/user/forgot-password/verify', params)
}