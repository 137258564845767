import React, { useState } from 'react'

import { useSelector } from "react-redux";

import Search from './Topbar/Search';
import Menu from './Topbar/Menu';

function Topbar() {

    //REDUX
    const polygon = useSelector(state => state.polygon);
    const restaurantShare = useSelector(state => state.restaurantShare);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    return (
        <div className="topbar-container-outer">
            <div className="topbar-container">
                {restaurantShare || <Search
                    isMenuOpen={isMenuOpen}
                    handleToggleMenu={handleToggleMenu}
                />}
                <Menu
                    isMenuOpen={isMenuOpen}
                    handleToggleMenu={handleToggleMenu}
                />
            </div>
        </div>
    )
}
export default Topbar