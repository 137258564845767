import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import * as ApiRestaurant from "../../api/restaurants";

import DropdownIcon from "@material-ui/icons/ArrowDropDown";

import SearchInput from "../includes/Search/SearchInput";
import SearchFilters from "../includes/Search/SearchFilters";
import SearchItems from "../includes/Search/SearchItems";

function Search(props) {
  const { handleToggleMenu, isMenuOpen } = props;

  const dispatch = useDispatch();
  const options = useSelector((state) => state.options);
  const polygon = useSelector((state) => state.polygon);
  const userLocation = useSelector((state) => state.userLocation);
  const isSearch = useSelector((state) => state.isSearch);

  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchLoadMore, setIsSearchLoadMore] = useState(false);
  const [isSearchMinimized, setIsSearchMinimized] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [searchCountries, setSearchCountries] = useState([]);
  const [searchLikes, setSearchLikes] = useState("desc");
  const [searchMeats, setSearchMeats] = useState([]);
  const [searchNear, setSearchNear] = useState(false);
  const [searchNearPrompt, setSearchNearPrompt] = useState(false);

  const [searchItems, setSearchItems] = useState([]);
  const [searchTotal, setSearchTotal] = useState(null);
  const [searchIndex, setSearchIndex] = useState(0);
  const [searchPageItems, setSearchPageItems] = useState(5);

  useEffect(() => {
    if (polygon) {
      let filterMeats = options.meats.filter((data) => {
        return data.meat_countries.includes(polygon.country.name) || null;
      });

      let filterMeatsSelected = searchMeats.filter((data) => {
        return filterMeats.map((data) => data.value).includes(data) || null;
      });

      setSearchMeats(filterMeatsSelected);
    }
  }, [options, polygon]);

  useEffect(() => {
    if (isSearch && polygon) {
      getSearchRestaurants(0)
    }
  }, [polygon]);

  useEffect(() => {
    if (userLocation.position) {
      if (searchNearPrompt) {
        setSearchNearPrompt(false);
        setSearchNear(true);
      }
    }
  }, [searchNearPrompt, userLocation]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "searchName":
        setSearchName(value);
        break;
      case "searchLikes":
        setSearchLikes(value);
        break;
      case "searchNear":
        if (!searchNear) {
          dispatch({ type: "userLocation", get: true });
          setSearchNearPrompt(true);
        } else {
          setSearchNear(false);
        }
        break;
      default:
        break;
    }
  };

  const handleSearchLoadMore = (value) => setIsSearchLoadMore(value);

  const handleSearchCountries = (value) => setSearchCountries(value);

  const handleSearchMeats = (value) => setSearchMeats(value);

  const handleSearchMinimized = (value) => setIsSearchMinimized(value)

  const handleMenuViews = (data) => {
    switch (data) {
      case "search":
        setIsFilterOpen(false);
        setIsSearchMinimized(!isSearchMinimized);
        break;
      case "filter":
        setIsSearchMinimized(true);
        setIsFilterOpen(!isFilterOpen);
        break;
      case "menu":
        setIsFilterOpen(false);
        setIsSearchMinimized(true);
        handleToggleMenu(!isMenuOpen);
        break;
      default:
        break;
    }
  };

  const searchRestaurants = (e) => {
    e.preventDefault();

    dispatch({ type: "isSearch", payload: true });

    setIsSearchLoading(true);
    setIsSearchLoadMore(false);
    setIsSearchMinimized(false);

    setIsFilterOpen(false);

    setSearchItems([]);
    setSearchIndex(0);
    getSearchRestaurants(0);
  };

  const clearSearch = (e) => {
    e.preventDefault();

    dispatch({ type: "isSearch", payload: false });
    dispatch({ type: "searchRestaurant", payload: null });

    setIsSearchLoading(false);
    setIsSearchLoadMore(false);

    setSearchItems([]);
    setSearchName("");
    setSearchIndex(0);
  };

  const getSearchRestaurants = (searchIndex) => {
    ApiRestaurant.searchRestaurants({
      polygonCountry: polygon ? polygon.country.name : null,

      searchIndex,
      searchPageItems,

      searchName,
      searchCountries,
      searchLikes,
      searchMeats,
      searchNear,

      userLocation,
    })
      .then((res) => {

        setSearchTotal(res.data.restaurants.total);
        setSearchIndex(res.data.next_page_index);

        if (res.data.current_page_index > 0) {
          setIsSearchLoadMore(false);
          setSearchItems([...searchItems, ...res.data.restaurants.items]);
        } else {
          setIsSearchLoading(false);
          setSearchItems(res.data.restaurants.items);
        }
      })
      .catch((error) => {
        dispatch({ type: "isSearch", payload: false });

        setIsSearchLoading(false);
        setIsSearchLoadMore(false);
        alert(error);
      });
  };

  return (
    <div className="search-container">
      <SearchInput
        handleInputChange={handleInputChange}
        handleMenuViews={handleMenuViews}
        searchRestaurants={searchRestaurants}
        clearSearch={clearSearch}
        isFilterOpen={isFilterOpen}
        searchName={searchName}
      />

      <SearchFilters
        isFilterOpen={isFilterOpen}
        searchMeats={searchMeats}
        searchCountries={searchCountries}
        searchNear={searchNear}
        handleInputChange={handleInputChange}
        handleSearchCountries={handleSearchCountries}
        handleSearchMeats={handleSearchMeats}
        searchRestaurants={searchRestaurants}
      />

      <SearchItems
        isSearchMinimized={isSearchMinimized}
        isSearchLoading={isSearchLoading}
        isSearchLoadMore={isSearchLoadMore}
        searchIndex={searchIndex}
        searchTotal={searchTotal}
        searchItems={searchItems}
        handleSearchLoadMore={handleSearchLoadMore}
        handleSearchMinimized={handleSearchMinimized}
        getSearchRestaurants={getSearchRestaurants}
      />

      {!isSearch || (
        <button
          className="search-minimize btn"
          onClick={() => handleMenuViews("search")}
        >
          <DropdownIcon className={isSearchMinimized ? "minimize" : ""} />
        </button>
      )}
    </div>
  );
}

export default Search;
