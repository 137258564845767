import React, { useEffect, useState } from 'react'

import * as ApiAuth from "../../api/auth";

import ErrorMessage from '../includes/ErrorMessage';
import Message from '../includes/Message';
import Alert from '../includes/Alert';

import * as Helper from '../../helpers/functions/general';

function Register(props){

    const [isLoading, setIsLoading] =  useState(false)
    const [isCompleted, setIsCompleted] =  useState(false)

    const [errorForm, setErrorForm] = useState({})
    const [ApiResponse, setApiReponse] = useState(null)

    const [email, setEmail] =  useState('')
    const [username, setUsername] =  useState('')
    const [password, setPassword] =  useState('')

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch(name) {
            case 'email':
                setEmail(value)
            break;
            case 'username':
                setUsername(value)
            break;
            case 'password':
                setPassword(value)
            break;
            default:
            break;
        }
    }

    const validateForm = () => {
        let errors = {}

        if(email.length <= 0){
            errors.email = 'Email is required'
        }else{
            if(Helper.regexEmail(email)){
                errors.email = false
            }else{
                errors.email = 'Enter a valid email'
            }
        }

        if(username.length <= 0){
            errors.username = 'Username is required'
        }else{
            if(Helper.regexEnglish(username)){
                if(!Helper.regexSpace(username)){
                    errors.username = false
                }else{
                    errors.username = "Username can't contain spaces"
                }
            }else{
                errors.username = 'Only english characters allowed'
            }
        }

        if(password.length <= 0){
            errors.password = 'Password is required'
        }else{
            if(password.length < 6){
                errors.password = 'Password should be 6 characters or more'
            }else{
                errors.password = false
            }
        }

        setErrorForm(errors)

        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }

    const handleFormSubmit = () => {
        if(!validateForm()){
            setIsLoading(true)
            register()
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        props.handleForm('Sign in')
    }

    const register = () => {
        ApiAuth.register({
            email: email,
            username: username,
            password: password,
        })
        .then((res) => {
            setIsLoading(false)
            setIsCompleted(true)
        })
        .catch((error) => {
            setIsLoading(false)

            if(error.response.data.errors){
                let errors = {}
                errors.email = error.response.data.errors.email ? "Account with this email already exists" : false;
                errors.username = error.response.data.errors.username ? "Username already taken" : false;
                setErrorForm(errors)
            }else{
                setApiReponse(error.response.data)
            }
        })
    }

    const renderForm = () => {
        return (
            <div id="register-form">
                <div className="form-group">
                    <label>Email Address</label>
                    <input 
                        className={`form-control ${!errorForm.email || 'error'}`} 
                        name="email" 
                        onChange={handleInputChange}
                    />  
                    <ErrorMessage
                        message={errorForm.email}
                    />
                </div>
                <div className="form-group">
                    <label>Username</label>
                    <input 
                        className={`form-control ${!errorForm.username || 'error'}`} 
                        name="username" 
                        type="text"
                        onChange={handleInputChange}
                    />  
                    <ErrorMessage
                        message={errorForm.username}
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input 
                        className={`form-control ${!errorForm.password || 'error'}`} 
                        name="password" 
                        type="password"
                        onChange={handleInputChange}
                    />
                    <ErrorMessage
                        message={errorForm.password}
                    />
                </div>

                <div className="actions">
                    <button 
                        className="btn" 
                        disabled={isLoading}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    <button 
                        className="btn" 
                        disabled={isLoading}
                        onClick={handleFormSubmit}
                    >
                        Submit
                    </button>
                </div>  
                <Alert
                    content={ApiResponse}
                />
            </div>
        )
    }


    return (
        !isCompleted ?
            renderForm()
        :
        <Message
            message={'Thanks for signing up! A verification link has been sent to your email, please click on it to activate your account. Please check your spam folder too!'}
            buttonText={'Back'}
            handleButton={handleCancel}
        /> 
    )   
}

export default Register