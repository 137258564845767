import { useSelector, useDispatch } from "react-redux";

import * as Helper from "../../../helpers/functions/general";

import DirectionIcon from "@material-ui/icons/Directions";
import ShareIcon from "@material-ui/icons/Share";

import Spinner from "../../includes/Spinner";

function SearchItems(props) {
  const {
    isSearchMinimized,
    isSearchLoading,
    isSearchLoadMore,
    searchIndex,
    searchTotal,
    searchItems,
    handleSearchLoadMore,
    handleSearchMinimized,
    getSearchRestaurants,
  } = props;

  //REDUX
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.userLocation);
  const isSearch = useSelector((state) => state.isSearch);
  const searchRestaurant = useSelector((state) => state.searchRestaurant);

  const handleSearchItemsScroll = (event) => {
    const target = event.target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      if (!isSearchLoadMore && searchIndex) {
        handleSearchLoadMore(true);
        getSearchRestaurants(searchIndex);
      }
    }
  };

  const handleSelectRestaurant = (data) => {
    if (!searchRestaurant) {
      if (window.innerWidth <= 600) {
        handleSearchMinimized(true);
      }

      dispatch({ type: "searchRestaurant", payload: data });
    } else {
      if (searchRestaurant.id !== data.id) {
        if (window.innerWidth <= 600) {
          handleSearchMinimized(true);
        }
        
        dispatch({ type: "searchRestaurant", payload: data });
      } else {
        dispatch({ type: "searchRestaurant", payload: null });
      }
    }
  };

  const renderCount = () => {
    return (
      searchItems.length <= 0 || (
        <div className="search-count">
          <p>
            {searchItems.length} out of {searchTotal} results
          </p>
        </div>
      )
    );
  };

  const renderList = () => {
    return searchItems.length > 0 ? (
      searchItems.map((data) => (
        <li
          key={data.id}
          className={`list-group-item ${
            searchRestaurant
              ? searchRestaurant.id === data.id
                ? "selected"
                : ""
              : null
          }`}
          onClick={(e) => handleSelectRestaurant(data)}
        >
          <div className="name-address">
            <h6>{data.name}</h6>
            <p>{Helper.formatAddress(data.location)}</p>
          </div>

          <div className="directions-share-distance">
            <div className="buttons-container">
              <a
                className="btn"
                href={Helper.generateDirectionsURL(data.position)}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <DirectionIcon className="direction" />
              </a>
              <button
                className="btn"
                onClick={(e) => Helper.generateShareLink(e, data)}
              >
                <ShareIcon className="share" />
              </button>
            </div>
            {!userLocation.position || (
              <small>
                {Helper.computeRestaurantDistance(
                  userLocation.position,
                  data.position
                )}
              </small>
            )}
          </div>
        </li>
      ))
    ) : (
      <li className="list-group-item empty">No results found</li>
    );
  };

  return (
    <div
      className={`search-items-container ${
        isSearchMinimized ? "minimize" : ""
      }`}
    >
      {renderCount()}

      {isSearch && !isSearchLoading ? (
        <ul
          className="search-items list-group"
          onScroll={handleSearchItemsScroll}
        >
          {renderList()}
          {!isSearchLoadMore || (
            <li className="list-group-item">
              <Spinner />
            </li>
          )}
        </ul>
      ) : null}

      {!isSearchLoading || (
        <div className="loading">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default SearchItems;
