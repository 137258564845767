import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";

import * as ApiUser from "../api/user";

import ErrorMessage from '../components/includes/ErrorMessage';
import Message from '../components/includes/Message';
import Alert from '../components/includes/Alert';

function ResetPassword(){
    
    //REACT ROUTER
    const { forgot_password_unique_id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] =  useState(false)

    const [errorForm, setErrorForm] = useState({})
    const [errorApi, setErrorApi] = useState(null)

    const [password, setPassword] =  useState('')
    const [passwordConfirm, setPasswordConfirm] =  useState('')

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch(name) {
            case 'password':
                setPassword(value)
            break;
            case 'password-confirm':
                setPasswordConfirm(value)
            break;
            default:
            break;
        }
    }

    const validateForm = () => {
        let errors = {}

        if(password.length <= 0){
            errors.password = true
        }else{
            if(password.length < 6){
                errors.password = 'Password should be 6 characters or more'
            }else{
                errors.password = false
            }
        }

        if(passwordConfirm.length <= 0){
            errors.password_confirm = true
        }else{
            if(passwordConfirm !== password){
                errors.password_confirm = 'Passwords are not the same'
            }else{
                errors.password_confirm = false
            }
        }

        setErrorForm(errors)

        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }

    const handleFormSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if(!validateForm()){
            setIsLoading(true)
            forgotPasswordVerify()
        } 
    } 

    const forgotPasswordVerify = () => {
        ApiUser.forgotPasswordVerify({
            unique_id: forgot_password_unique_id,
            password: password
        })
        .then((res) => {
            setIsLoading(false)
            setIsCompleted(true)

            setTimeout(function () {
                window.location.href = "/";
            }, 3000);
        })
        .catch((error) => {
            setIsLoading(false)
            setErrorApi(error.response.data)
        })
    }

    const renderForm = () => {
        return (
            <form id="reset-password-form" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label>New Password</label>
                    <input 
                        className={`form-control ${!errorForm.password || 'error'}`} 
                        name="password" 
                        type="password"
                        onChange={handleInputChange}
                    />
                    <ErrorMessage
                        message={errorForm.password}
                    />
                </div>
                <div className="form-group">
                    <label>Confirm Password</label>
                    <input 
                        className={`form-control ${!errorForm.password_confirm || 'error'}`} 
                        name="password-confirm" 
                        type="password"
                        onChange={handleInputChange}
                    />
                    <ErrorMessage
                        message={errorForm.password_confirm}
                    />
                </div>
                <button 
                    className="btn" 
                    disabled={isLoading}
                >
                    Submit
                </button>
                {!errorApi || <Alert
                    alert_type={'danger'}
                    content={errorApi}
                />}
            </form>
        )
    }

    return (
        <div className="reset-password-container">
            <div className="card">
                <div className="card-header">
                    Reset Account Password
                </div>
                <div className="card-body">
                    {!isCompleted ?
                        renderForm()
                    :
                        <Message
                            message={'Your password has been updated, you will be automatically redirected to sign in.'}
                        /> 
                    }
                </div>
            </div>
        </div>
    )
}

export default ResetPassword