export const searchRestaurant = (state = null, action) => {
    switch (action.type) {

        case 'searchRestaurant':
            return action.payload

        default:
            return state
            
    }
}
export default searchRestaurant;