export const isSearchReducer = (state = false, action) => {
    switch (action.type) {

        case 'isSearch':
            return action.payload

        default:
            return state
            
    }
}
export default isSearchReducer;