import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from "react-redux";

import * as ApiRestaurant from '../../api/restaurants';

import * as Helper from '../../helpers/functions/general';

import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import ThumbsUpIcon from '@material-ui/icons/ThumbUpAlt';

import Spinner from '../includes/Spinner';

import RestaurantContact from './RestaurantContact';

import Slider from "react-slick";

function ViewRestaurant(){

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const restaurants = useSelector(state => state.restaurants);
    const restaurantView = useSelector(state => state.restaurantView);

    const [isContact, setIsContact] =  useState(false)

    const [isLoading, setIsLoading] =  useState(true)
    const [restaurantID, setRestaurantID] =  useState(null)
    const [restaurantName, setRestaurantName] =  useState(null)
    const [restaurantPhoneNumbers, setRestaurantPhoneNumbers] =  useState([])
    const [restaurantAddress, setRestaurantAddress] =  useState(null)
    const [restaurantMeats, setRestaurantMeats] =  useState([])

    const slider_settings = {
        centerMode: false,
        dots: false,
        autoplay: false,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 3,
        infinite: restaurantMeats.length > 3 ? true : false,
        arrows: restaurantMeats.length > 3 ? true : false,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: restaurantMeats.length > 2 ? 2 : 3,
                    infinite: restaurantMeats.length > 2 ? true : false,
                    arrows: restaurantMeats.length > 2 ? true : false
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 1,
                    infinite: restaurantMeats.length > 1 ? true : false,
                    arrows: restaurantMeats.length > 1 ? true : false
                }
            }
        ]
    };

    useEffect(() => {
        getSingleRestaurant()
    }, []);
    
    const updateMeatRating = (restaurant_meat_id, user_rating) => {
        //MEATS ARRAY
        let restaurant_array = [...restaurants];
        let restaurant_index = restaurant_array.findIndex(data => data.id === restaurantID);

        //RESTAURANTS ARRAY
        let restaurant_meats_array = [...restaurantMeats]
        let restaurant_meats_index = restaurant_meats_array.findIndex(data => data.id === restaurant_meat_id)

        //MEATS ARRAY
        restaurant_meats_array[restaurant_meats_index].user_rating = !user_rating ? true : false;
        restaurant_meats_array[restaurant_meats_index].rating = !user_rating ?  parseInt(restaurant_meats_array[restaurant_meats_index].rating) + 1 : parseInt(restaurant_meats_array[restaurant_meats_index].rating) - 1;

        //RESTAURANTS ARRAY
        restaurant_array[restaurant_index].rating = !user_rating ?  parseInt(restaurant_array[restaurant_index].rating) + 1 : parseInt(restaurant_array[restaurant_index].rating) - 1;
        
        dispatch({type: 'restaurants', payload: restaurant_array})
        setRestaurantMeats(restaurant_meats_array)
    }

    const toggleRestaurantContactForm = () => {
        if(isContact){
            setIsContact(false)
        }else{
            setIsContact(true)
        }
    }

    const closeModal = () => {
        dispatch({type: 'restaurantView', payload: null})
        dispatch({type: 'user_action', payload: null})
    }

    const getSingleRestaurant = () => {
        ApiRestaurant.getSingleRestaurant({
            restaurant_id: restaurantView
        })
        .then((res) => {
            setIsLoading(false)

            setRestaurantID(res.data.id)
            setRestaurantName(res.data.name)
            setRestaurantPhoneNumbers(res.data.phone_number)
            setRestaurantMeats(res.data.restaurant_meats)

            setRestaurantAddress(Helper.formatAddress(res.data.location))
        })
        .catch((error) => {
            alert(error)
        });
    }

    const insertMeatRate = (restaurant_meat_id, user_rating) => {
        ApiRestaurant.insertMeatRate({
            restaurant_meat_id: restaurant_meat_id,
        })
        .then((res) => {
            updateMeatRating(restaurant_meat_id, user_rating)
        })
        .catch((error) => {
            alert(error)
        })
    }

    const renderView = () => {
        return (
            <div className="modal-body">
                {restaurantPhoneNumbers.length <= 0 || <div className="form-group">
                    <label>{restaurantPhoneNumbers.length > 1 ? 'Phone Numbers' : 'Phone Number'}</label>  
                    <div>
                        {restaurantPhoneNumbers
                        .map((data, i) =>
                            <span key={i}>
                                <a className="phone-numbers" id={i} href={`tel:${data}`} rel="noreferrer">
                                    {data}
                                </a>{i < restaurantPhoneNumbers.length - 1 ? ', ' : null}
                            </span>
                        )}
                    </div>
                </div>}
                
                <div className="meat-types-container">
                    <label>Shawarmas</label>  
                    <Slider {...slider_settings}>
                        {restaurantMeats.map((data, i) => 
                            <div key={i} className="item-container">
                                <div className="item">
                                    <h6 className="title">{data.name}</h6>
                                    <div className="meat-container">
                                        <div className="meat-item">
                                            <h6>{Helper.formatNumbers(data.rating)} likes</h6>
                                            {!auth || 
                                                <button 
                                                    className="btn" 
                                                    onClick={() => insertMeatRate(data.id, data.user_rating)}
                                                >
                                                    <ThumbsUpIcon 
                                                        className={`${data.user_rating ? 'active' : ''}`}
                                                    />
                                                </button>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </Slider>   
                </div>

                {!auth ? 
                    <button 
                        className="btn btn-sign-in"
                        onClick={() => dispatch({ type: 'user_action', action: 'login_user' })}
                    >
                        <span>Sign in</span> to start adding likes!
                    </button>
                :
                    null
                }
                
            </div>
        )
    }



    return (
        <div className={`modal-content view-restaurant ${isLoading ? 'loading' : ''}`}>
            
            <div className="modal-header">
                <div className="title-container">
                <h6 className="text">{restaurantName}</h6>
                <p>{restaurantAddress}</p>
                </div>
                <div className="buttons-container">

                    {!isLoading && !isContact ? <button
                        className="btn report"
                        onClick={toggleRestaurantContactForm}
                    >
                        <HelpIcon/>
                    </button> : null}
                    
                    <button
                        className="btn btn-close"
                        onClick={!isContact ? closeModal : toggleRestaurantContactForm}
                    >
                        <CloseIcon />
                    </button>
                </div>
            </div>

            {!isLoading ?
                !isContact ? 
                    renderView() 
                : 
                    <RestaurantContact 
                        restaurant_id={restaurantID} 
                        restaurant_name={restaurantName} 
                        restaurant_address={restaurantAddress} 
                        toggleRestaurantContactForm={toggleRestaurantContactForm}
                    />
            :
                <Spinner/>
            }

        </div>
    )   
}
export default ViewRestaurant