export const restaurantShareReducer = (state = false, action) => {
    switch (action.type) {

        case 'restaurantShare':
            return action.payload

        default:
            return state
            
    }
}
export default restaurantShareReducer;