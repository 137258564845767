import { useSelector, useDispatch } from "react-redux";

import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import FilterIcon from "@material-ui/icons/FilterList";

function SearchInput(props) {
  const {
    handleInputChange,
    handleMenuViews,
    searchRestaurants,
    clearSearch,
    isFilterOpen,
    searchName,
  } = props;

  //REDUX
  const isSearch = useSelector((state) => state.isSearch);

  const filterMenu = (e) => {
    e.preventDefault();
    handleMenuViews("filter");
  };

  const menu = (e) => {
    e.preventDefault();
    handleMenuViews("menu");
  };

  return (
    <form className="input-form" onSubmit={searchRestaurants}>
      <button type="submit" className="search">
        <SearchIcon />
      </button>
      <input
        className="form-control"
        name="searchName"
        type="text"
        placeholder="Search Restaurants"
        autoComplete="off"
        value={searchName}
        onChange={handleInputChange}
      />
      {!isSearch || (
        <button className="clear" onClick={clearSearch}>
          <ClearIcon />
        </button>
      )}
      <button
        className={`filter ${isFilterOpen ? "open" : ""}`}
        onClick={filterMenu}
      >
        <FilterIcon />
      </button>
      <button className="menu" onClick={menu}>
        <MenuIcon />
      </button>
    </form>
  );
}

export default SearchInput;
