import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from "react-redux";

import * as ApiAuth from "../../api/auth";

import Alert from '../includes/Alert';
import Spinner from '../includes/Spinner';

function Login(props){

    //REDUX
    const dispatch = useDispatch();
    
    const [isLoading, setIsLoading] =  useState(false)

    const [errorForm, setErrorForm] = useState({})
    const [errorApi, setErrorApi] = useState(null)

    const [username, setUsername] =  useState('')
    const [password, setPassword] =  useState('')

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch(name) {
            case 'username':
                setUsername(value)
            break;
            case 'password':
                setPassword(value)
            break;
            default:
            break;
        }
    }

    const handleFormSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
                
        if(!validateForm()){
            setIsLoading(true)
            authLocal()
        }
    }

    const handleForgotPassword = (e) => {
        e.preventDefault()
        props.handleForm('Forgot Password')
    }

    const handleSignUp = (e) => {
        e.preventDefault()
        props.handleForm('Register')
    }

    const validateForm = () => {
        let errors = {}

        errors.username = username ? false : true
        errors.password = password ? false : true
        setErrorForm(errors)

        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }

    const authLocal = () => {
        ApiAuth.authLocal({
            username: username,
            password: password,
        })
        .then((res) => {
            dispatch({type: 'auth', payload: username})

            setIsLoading(false)
            props.handleCloseModal()
        })
        .catch((error) => {
            setPassword('')

            setIsLoading(false)
            setErrorApi(error.response.data)
        })
    }

    const renderForm = () => {
        return (
            <form id="login-form" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label>Username</label>
                    <input 
                        className={`form-control ${!errorForm.username || 'error'}`} 
                        name="username" 
                        type="text"
                        onChange={handleInputChange}
                        value={username}
                    />  
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input 
                        className={`form-control ${!errorForm.password || 'error'}`} 
                        name="password" 
                        type="password"
                        onChange={handleInputChange}
                        value={password}
                    />
                </div>
                <div className="actions">
                    <button className="btn btn-login">Login</button>
                    <button 
                        className="btn btn-forgot-password"
                        onClick={handleForgotPassword}
                    >
                        Forgot your password?
                    </button>  
                </div>

                {!errorApi || <Alert
                    alert_type={'danger'}
                    content={errorApi}
                />}

                <div className="separator">
                    or
                </div>

                <div className="social-login-container">
                    <a href='/auth/google' rel="noreferrer">
                        <img alt="Icon by Freepik" src="/images/icons/icon_google.svg"/>
                    </a>
                    <a href='/auth/facebook' rel="noreferrer">
                        <img alt="Icon by Freepik" src="/images/icons/icon_facebook.svg"/>
                    </a>
                    <a href='/auth/twitter' rel="noreferrer">
                        <img alt="Icon by Freepik" src="/images/icons/icon_twitter.svg"/>
                    </a>
                </div>

                <button 
                    className="btn btn-sign-up"
                    onClick={(e) => handleSignUp(e)}
                >
                    Don't have an Account? <span>Sign up</span>
                </button>

            </form>
        )
    }

    return (
        
        !isLoading ?
            renderForm()
        :
            <Spinner/>
    )   
}

export default Login