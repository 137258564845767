import store from "../../redux/store";

const english_regex = /^[a-zA-Z0-9$@‘’“”`"$!%*;:?&#-_., +]+$/;
const phone_number_regex = /^([+]|[0-9 ])*$/;
const email_regex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const space_regex = /\s/g;

export const capitalizeString = (data) => {
  let words = data.trim().toLowerCase().split(" ");
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};
export const regexEnglish = (data) => {
  if (english_regex.test(data)) {
    return true;
  }
  return false;
};
export const regexPhoneNumber = (data) => {
  if (phone_number_regex.test(data)) {
    return true;
  }
  return false;
};
export const regexEmail = (data) => {
  if (email_regex.test(data)) {
    return true;
  }
  return false;
};
export const regexSpace = (data) => {
  if (space_regex.test(data)) {
    return true;
  }
  return false;
};
export const removeSpaces = (data) => {
  return data.replace(/\s/g, "");
};
export const generateAvatar = (data) => {
  let words = data.split(" ");
  let avatar = "";
  for (var i = 0; i < words.length; i += 1) {
    if (avatar.length < 2) {
      avatar = avatar + words[i][0].toUpperCase();
    }
  }
  return <span>{avatar}</span>;
};

//SEARCH
export const formatAddress = (data) => {
  let complete_address = "";
  if (data.address) {
    complete_address = complete_address + data.address + ", ";
  }
  if (data.state) {
    complete_address = complete_address + data.state + ", ";
  }
  if (data.country) {
    complete_address = complete_address + data.country;
  }
  return complete_address;
};

export const formatNumbers = (data) => {
  return data.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const computeRestaurantDistance = (start_position, end_position) => {
  let R = 6371; // km

  const start_lat = start_position.lat;
  const start_lng = start_position.lng;

  const end_lat = end_position.lat;
  const end_lng = end_position.lng;

  let dLat = ((end_lat - start_lat) * Math.PI) / 180;
  let dLon = ((end_lng - start_lng) * Math.PI) / 180;
  let lat1 = (start_lat * Math.PI) / 180;
  let lat2 = (start_lng * Math.PI) / 180;

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distance = R * c;

  if (distance < 1) {
    return distance.toFixed(2) * 1000 + " m";
  } else {
    return distance.toFixed(1) + " km";
  }
};

export const generateDirectionsURL = (data) => {
  return `https://www.google.com/maps/dir/?api=1&destination=${data.lat},${data.lng}`;
};

export const generateShareLink = (e, data) => {
  e.stopPropagation();

  let url = `${process.env.REACT_APP_HOST}/${data.id}/${data.url}`;
  let dummy_element = document.createElement("input");

  document.body.appendChild(dummy_element);
  dummy_element.setAttribute("value", url);
  dummy_element.select();
  document.execCommand("copy");
  document.body.removeChild(dummy_element);

  store.dispatch({
    type: "snackbar",
    duration: 4000,
    message: "Restaurant copied to clipboard",
  });
};

export const formatSelectedMeats = (data) => {
  return data.map((data) => {
    return {
      meat_id: data.id,
      name: data.name,
      has_spicy: data.has_spicy,
    };
  });
};

export const formatPhoneNumbers = (data) => {
  let array = [];
  data.forEach((data) => {
    array.push(data.value);
  });
  return array;
};
