export const restaurantsReducer = (state = [], action) => {
    switch (action.type) {

        case 'restaurants':
            return action.payload

        default:
            return state
            
    }
}
export default restaurantsReducer;