import React from 'react';

function Message(props) {
    return (
        <div className="message-container-outer modal-body">
            <div className="message-container">
                <h5 className="message">{props.message}</h5>
                {!props.buttonText || <button className="btn" onClick={(e) => props.handleButton(e)}>{props.buttonText}</button>}
            </div>
        </div>
    )
}

export default Message