import React, { useEffect, useState } from 'react'

import { useDispatch } from "react-redux";

import * as ApiUser from '../api/user';

import CloseIcon from '@material-ui/icons/Close';

import ErrorMessage from './includes/ErrorMessage';
import Message from './includes/Message';
import Alert from './includes/Alert';
import Spinner from './includes/Spinner';

import * as Helper from '../helpers/functions/general';

import $ from 'jquery'

function Account(){

    //REDUX
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    
    const [errorForm, setErrorForm] = useState({})
    const [ApiResponse, setApiReponse] = useState(null)

    const [social, setSocial] =  useState(null)
    const [name, setName] =  useState('')
    const [username, setUsername] =  useState('')
    const [email, setEmail] =  useState('')
    const [password, setPassword] =  useState('')

    useEffect(() => {
        getUser()
    }, []);

    const closeModal = () => {
        dispatch({type: 'user_action', action: null})
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        switch(name) {
            case 'username':
                setUsername(value)
            break;
            case 'email':
                setEmail(value)
            break;
            case 'password':
                setPassword(value)
            break;
            default:
            break;
        }
    }

    const handleFormSubmit = () => {
        if(!validateForm()){
            setIsLoading(true)
            updateUser()
        }
    }

    const handleAlertDismiss = () => setApiReponse(null)

    const validateForm = () => {
        let errors = {}

        if(username.length <= 0){
            errors.username = 'Username is required'
        }else{
            if(Helper.regexEnglish(username)){
                if(!Helper.regexSpace(username)){
                    errors.username = false
                }else{
                    errors.username = "Username can't contain spaces"
                }
            }else{
                errors.username = 'Only english characters allowed'
            }
        }

        if(email.length <= 0){
            errors.email = 'Email is required'
        }else{
            if(Helper.regexEmail(email)){
                errors.email = false
            }else{
                errors.email = 'Enter a valid email'
            }
        }

        if(password.length >= 1 && password.length < 6){
            errors.password = 'Password should be 6 characters or more'
        }else{
            errors.password = false
        }

        setErrorForm(errors)
        
        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }

    const getUser = () => {
        ApiUser.getUser()
        .then((res) => {   
            setIsLoading(false)

            setSocial(res.data.social)
            setUsername(res.data.username)
            setName(res.data.name)
            setEmail(res.data.email)
        })
        .catch((error) => {
            setIsLoading(false)

            if(error.response.data.errors){
                let errors = {}
                errors.email = error.response.data.errors.email ? "Account with this email already exists" : false;
                errors.username = error.response.data.errors.username ? "Username already taken" : false;
                setErrorForm(errors)
            }else{
                setApiReponse(error.response.data)
            }
        });
    }
    const updateUser = () => {
        ApiUser.updateUser({
            email,
            username,
            password
        })
        .then((res) => {   
            dispatch({type: 'auth', payload: username})
            
            setIsLoading(false)
            setPassword('')

            setApiReponse(res.data)
        })
        .catch((error) => {
            setIsLoading(false)

            if(error.response.data.errors){
                let errors = {}
                errors.email = error.response.data.errors.email ? "Account with this email already exists" : false;
                errors.username = error.response.data.errors.username ? "Username already taken" : false;
                setErrorForm(errors)
            }else{
                setApiReponse(error.response.data)
            }
        });
    }
    
    
    const renderView = () => {
        return (
            <div id="account-form" className="modal-body">
                <div className="form-group">
                    <label>Email address{social ? <span class="badge">{social}</span> : null}</label>
                    <input 
                        className={`form-control ${!errorForm.email || 'error'}`} 
                        name="email"
                        type="text" 
                        value={email} 
                        onChange={handleInputChange}
                        readOnly={social ? true : false}
                     />  
                    <ErrorMessage
                        message={errorForm.email}
                    />
                </div>
                {!social ? <div className="form-group">
                    <label>Username</label>
                    <input className={`form-control ${!errorForm.username || 'error'}`} name="username" type="text" value={username} onChange={handleInputChange}/>  
                    <ErrorMessage
                        message={errorForm.username}
                    />
                </div> : null}
                {social ? <div className="form-group">
                    <label>Name</label>
                    <input 
                        className={`form-control ${!errorForm.name || 'error'}`} 
                        name="name" 
                        type="text" 
                        value={name} 
                        onChange={handleInputChange}
                        readOnly={social ? true : false}
                    /> 
                </div> : null}
                {!social ? <div className="form-group">
                    <label>Update Password</label>
                    <input 
                        className={`form-control ${!errorForm.password || 'error'}`} 
                        name="password" 
                        type="password"
                        value={password} 
                        onChange={handleInputChange}
                    />  
                    <ErrorMessage
                        message={errorForm.password}
                    />
                </div> : null}
                <div className="actions" style={{justifyContent: social ? 'flex-end' : 'space-between'}}>
                    {/* <button 
                        className="btn btn-delete" 
                        disabled={isLoading}
                    >
                        Delete Account
                    </button> */}
                    {!social ? <button 
                        className="btn" 
                        disabled={isLoading}
                        onClick={handleFormSubmit}
                    >
                        Save Changes
                    </button> : null}
                </div>  
                <Alert
                    content={ApiResponse}
                    handleAlertDismiss={handleAlertDismiss}
                />
            </div>
        )
    }
    
    return (
        <div className="modal-content account-container">
            <div className="modal-header">
                <h6>Account</h6>
                <button
                    className="btn"
                    onClick={closeModal}
                >
                    <CloseIcon />
                </button>
            </div>
            
            {!isLoading ? 
                renderView() 
            :   
                <Spinner/>
            }
        </div>
    )   
}


export default Account