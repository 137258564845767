import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Autocomplete as AutocompleteComponent } from "@react-google-maps/api";

import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import * as MapHelpers from "../../../helpers/functions/map";
import * as MapConstants from "../../../helpers/map_options";

function Autocomplete(props) {
  const {
    searchAutoComplete,
    handleSetRestaurantMarked,
    handleSetSearchAutoComplete,
    handleSetRestaurantAutoComplete,
    handleClearAutoComplete,
  } = props;

  //REDUX
  const dispatch = useDispatch();
  const mapZoom = useSelector((state) => state.mapZoom);
  const polygon = useSelector((state) => state.polygon);

  const [autocomplete, setAutocomplete] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    setCountryCodes(MapHelpers.generateSearchCountryCodes())
  }, [polygon]);

  const onLoadAutocomplete = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    let location_data = autocomplete.getPlace();
    if (location_data) {
      if (location_data.geometry) {
        let position = {
          lat: location_data.geometry.location.lat(),
          lng: location_data.geometry.location.lng(),
        };

        if (mapZoom < 18) {
          dispatch({ type: "mapZoom", payload: 18 });
        }

        handleSetRestaurantAutoComplete(location_data);

        handleSetRestaurantMarked(position);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "searchAutoComplete":
        handleSetSearchAutoComplete(value);
        break;
      default:
        break;
    }
  };

  return (
    <AutocompleteComponent
      onLoad={onLoadAutocomplete}
      fields={MapConstants.map_options.autocomplete_options.fields}
      onPlaceChanged={onPlaceChanged}
      restrictions={{
        country: countryCodes,
      }}
    >
      <div className="autocomplete-search-container input-group">
        <div className="icon-search input-group-prepend">
          <SearchIcon />
        </div>
        <input
          className="autocomplete-input form-control"
          name="searchAutoComplete"
          type="text"
          placeholder="Search Locations"
          onChange={handleInputChange}
        />
        {searchAutoComplete.length <= 0 || (
          <div className="input-group-prepend icon-clear">
            <button onClick={handleClearAutoComplete}>
              <ClearIcon />
            </button>
          </div>
        )}
      </div>
    </AutocompleteComponent>
  );
}

export default Autocomplete;
