export const restaurantCountReducer = (state = [], action) => {

    const restaurantCountAdd = (country) => {
        return state.map((data) => {
            if(data.name === country){
                return Object.assign({}, data, {
                    count: parseInt(data.count) + 1
                })
            }
            return data;
        })
    }

    switch (action.type) {
        case 'restaurantCount':
            return action.payload
        case 'restaurantCountAdd':
            return restaurantCountAdd(action.country)
        default:
            return state
    }
}
export default restaurantCountReducer;