const INITIAL_STATE = {
    get: false,
    position: null
}
export const userLocationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'userLocation':
            return {
                get: action.get || INITIAL_STATE.get,
                position: action.position || INITIAL_STATE.position
            }
        default:
            return state
            
    }
}
export default userLocationReducer;