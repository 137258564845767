import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import * as ConstantsDropdown from "../../../helpers/dropdown_options";

import Dropdown from "../../includes/Dropdown";

function SearchFilters(props) {
  const {
    isFilterOpen,
    searchMeats,
    searchCountries,
    searchNear,
    handleInputChange,
    handleSearchCountries,
    handleSearchMeats,
    searchRestaurants,
  } = props;

  //REDUX
  const polygon = useSelector((state) => state.polygon);
  const options = useSelector((state) => state.options);

  const [meats, setMeats] = useState([]);

  useEffect(() => {
    if (polygon) {
      let filterMeats = options.meats.filter((data) => {
        return data.meat_countries.includes(polygon.country.name) || null;
      });

      let filterMeatsSelected = searchMeats.filter((data) => {
        return filterMeats.map((data) => data.value).includes(data) || null;
      });

      handleSearchMeats(filterMeatsSelected);
      setMeats(filterMeats);
    } else {
      setMeats(options.meats);
    }
  }, [options, polygon]);

  const handleMeatChange = (e, value) => {
    let search_meats_array = [...searchMeats];
    if (e.target.checked) {
      search_meats_array.push(value);
      handleSearchMeats(search_meats_array);
    } else {
      handleSearchMeats(search_meats_array.filter((meat) => meat !== value));
    }
  };

  return (
    <div className={`filter-container ${isFilterOpen ? "open" : ""}`}>
      {!polygon ? (
        <div className="form-group">
          <label>Country</label>
          <Dropdown
            placeholder={"All Countries"}
            options={options.countries}
            handleChange={handleSearchCountries}
            value={searchCountries}
          />
        </div>
      ) : null}
      <div className="form-group">
        <label>Likes</label>
        <select
          className="form-control custom-select"
          name="searchLikes"
          defaultValue={"desc"}
          onChange={handleInputChange}
        >
          {ConstantsDropdown.order_by_options.map((data, i) => (
            <option key={i} value={data.value}>
              {data.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label>Shawarmas</label>
        <div>
          {meats.map((data, i) => (
            <div key={i} className="form-check form-check-inline">
              <input
                id={`meat-${data.label}`}
                name={`meat-${data.label}`}
                type="checkbox"
                className="form-check-input"
                onChange={(e) => handleMeatChange(e, data.value)}
              />
              <label
                className="form-check-label"
                htmlFor={`meat-${data.label}`}
              >
                {data.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="form-check">
        <input
          id="searchNear"
          name="searchNear"
          type="checkbox"
          className="form-check-input"
          checked={searchNear}
          onChange={handleInputChange}
        />
        <label className="form-check-label" htmlFor="searchNear">
          Shawarmas near me
        </label>
      </div>

      <button className="btn search" onClick={searchRestaurants}>
        Search
      </button>
    </div>
  );
}

export default SearchFilters;
