import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CreatableSelect from "react-select/creatable";
import CloseIcon from "@material-ui/icons/Close";

import * as ApiRestaurant from "../../api/restaurants";

import ErrorMessage from "../includes/ErrorMessage";
import Alert from "../includes/Alert";
import Message from "../includes/Message";

import * as Helpers from "../../helpers/functions/general";

import $ from "jquery";

function AddRestaurant() {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.options);
  const polygon = useSelector((state) => state.polygon);
  const restaurantAdd = useSelector((state) => state.restaurantAdd);

  const [meatOptions, setMeatOptions] = useState([]);

  const [errorForm, setErrorForm] = useState({});
  const [errorApi, setErrorApi] = useState(null);

  const [restaurantName, setRestaurantName] = useState("");
  const [selectedMeats, setSelectedMeats] = useState([]);

  const [restaurantPhoneNumbersDisplay, setRestaurantPhoneNumbersDisplay] =
    useState([]);
  const [restaurantPhoneNumberInput, setrestaurantPhoneNumberInput] =
    useState("");
    
  const [isLoading, setIsLoading] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (options) {
      let filterMeats = options.meats.filter((data) => {
        return data.meat_countries.includes(polygon.country.name) || null;
      });
      setMeatOptions(filterMeats);
    }
  }, [options]);

  useEffect(() => {
    if (restaurantAdd.place) {
      setRestaurantName(restaurantAdd.place.name);

      if (restaurantAdd.place.phone_number) {
        let restaurant_phone_number = Helpers.removeSpaces(
          restaurantAdd.place.phone_number
        );
        let obj = {
          label: restaurant_phone_number,
          value: restaurant_phone_number,
        };

        if (Helpers.regexPhoneNumber(restaurant_phone_number)) {
          setRestaurantPhoneNumbersDisplay([
            ...restaurantPhoneNumbersDisplay,
            obj,
          ]);
        }
      }
    }
  }, [restaurantAdd]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "name":
        setRestaurantName(value);
        break;
      default:
        break;
    }
  };

  const handlePhoneNumbers = (value) => {
    if (value) {
      setRestaurantPhoneNumbersDisplay(value);
    } else {
      setRestaurantPhoneNumbersDisplay([]);
    }
  };

  const handlePhoneNumberInput = (value) => {
    setrestaurantPhoneNumberInput(value);
  };

  const handleKeyDown = (event) => {
    let phoneNumberCheck = restaurantPhoneNumbersDisplay.filter(
      (item) => item.value === restaurantPhoneNumberInput
    );
    switch (event.key) {
      case "Enter":
        if (
          Helpers.regexPhoneNumber(restaurantPhoneNumberInput) &&
          phoneNumberCheck.length <= 0 &&
          restaurantPhoneNumberInput.length > 0
        ) {
          let phone_number_no_spaces = Helpers.removeSpaces(
            restaurantPhoneNumberInput
          );

          let obj = {
            label: phone_number_no_spaces,
            value: phone_number_no_spaces,
          };

          setRestaurantPhoneNumbersDisplay([
            ...restaurantPhoneNumbersDisplay,
            obj,
          ]);
        }
        setrestaurantPhoneNumberInput("");
        event.preventDefault();
        break;
      case "Tab":
        if (
          Helpers.regexPhoneNumber(restaurantPhoneNumberInput) &&
          phoneNumberCheck.length <= 0 &&
          restaurantPhoneNumberInput.length > 0
        ) {
          let phone_number_no_spaces = Helpers.removeSpaces(
            restaurantPhoneNumberInput
          );

          let obj = {
            label: phone_number_no_spaces,
            value: phone_number_no_spaces,
          };

          setRestaurantPhoneNumbersDisplay([
            ...restaurantPhoneNumbersDisplay,
            obj,
          ]);
        }
        setrestaurantPhoneNumberInput("");
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleMeatOption = (event, data) => {
    if (event.target.checked) {
      let obj = {
        id: data.value,
        name: data.label,
        has_spicy: false,
      };
      setSelectedMeats([...selectedMeats, obj]);
    } else {
      setSelectedMeats(selectedMeats.filter((meat) => meat.id !== data.value));
    }
  };

  const handleFormSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!validateForm()) {
      setIsLoading(true);
      insertRestaurant();
    }
  };

  const closeModal = () => {
    if (restaurantAdd.action) {
      dispatch({ type: "restaurantAdd", action: "marked", position: restaurantAdd.position });
    }

    dispatch({ type: "user_action", action: null });
  };

  const validateForm = () => {
    let errors = {};

    if (restaurantName.length <= 0) {
      errors.restaurantName = "Name is required";
    } else {
      if (Helpers.regexEnglish(restaurantName)) {
        errors.restaurantName = false;
      } else {
        errors.restaurantName = "Only english characters are allowed";
      }
    }

    if (selectedMeats.length <= 0) {
      errors.selectedMeats = "Meats are required";
    } else {
      errors.selectedMeats = false;
    }

    setErrorForm(errors);

    return Object.keys(errors).some((data) => {
      return errors[data];
    });
  };

  const insertRestaurant = () => {
    ApiRestaurant.insertRestaurant({
      name: Helpers.capitalizeString(restaurantName),
      phone_number: Helpers.formatPhoneNumbers(restaurantPhoneNumbersDisplay),
      address: restaurantAdd.address.address,
      state: restaurantAdd.address.state,
      country: restaurantAdd.address.country,
      meats: Helpers.formatSelectedMeats(selectedMeats),
      lat: restaurantAdd.address.position.lat,
      lng: restaurantAdd.address.position.lng,
    })
      .then((res) => {
        dispatch({ type: "restaurantCountAdd", country: restaurantAdd.address.country });
        dispatch({ type: "restaurantAdd", action: null });
        dispatch({ type: 'snackbar', message: null })
        $(".pac-container").remove();

        setIsLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorApi(error.response.data);
      });
  };

  const renderForm = () => {
    return (
      <form
        id="add-restaurant-form"
        className="modal-body"
        onSubmit={handleFormSubmit}
      >
        <div className="form-group">
          <label>Name*</label>
          <input
            className={`form-control ${!errorForm.restaurantName || "error"}`}
            name="name"
            value={restaurantName}
            onChange={(e) => handleInputChange(e)}
            autoComplete="off"
          />
          <ErrorMessage message={errorForm.restaurantName} />
        </div>

        <div className="form-group">
          <label>Phone Numbers</label>
          <CreatableSelect
            className="phone-numbers-dropdown"
            name="phoneNumbers"
            type="tel"
            components={{
              DropdownIndicator: null,
            }}
            inputValue={restaurantPhoneNumberInput}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handlePhoneNumbers}
            onInputChange={handlePhoneNumberInput}
            onKeyDown={handleKeyDown}
            placeholder={false}
            value={restaurantPhoneNumbersDisplay}
          />
        </div>

        <div className="meat-types-container form-group">
          <label>Shawarmas*</label>
          <div className="meat-type-options-container">
            {meatOptions.map((data, i) => (
              <div key={i} className="form-check form-check-inline">
                <input
                  id={`meat-${data.label}`}
                  name={`meat-${data.label}`}
                  className="form-check-input"
                  type="checkbox"
                  value={data.value}
                  onChange={(e) => handleMeatOption(e, data)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`meat-${data.label}`}
                >
                  {data.label}
                </label>
              </div>
            ))}
          </div>

          <ErrorMessage message={errorForm.selectedMeats} />
        </div>
        <button className="btn" disabled={isLoading}>
          Submit
        </button>

        {!errorApi || <Alert alert_type={"danger"} content={errorApi} />}
      </form>
    );
  };

  return (
    <div className="modal-content add_restaurant">
      <div className="modal-header">
        <h6 className="text">Add Restaurant</h6>
        <button className="btn" onClick={closeModal} disabled={isLoading}>
          <CloseIcon />
        </button>
      </div>

      {!emailSent ? (
        renderForm()
      ) : (
        <Message
          message={
            "Thanks for adding a restaurant, we are excited for everyone to try out their shawarmas!"
          }
        />
      )}
    </div>
  );
}

export default AddRestaurant;