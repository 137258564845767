import React, { useEffect, useState } from 'react'

import { useSelector } from "react-redux";

import * as ApiMail from '../../api/message';

import ErrorMessage from '../includes/ErrorMessage';
import Message from '../includes/Message';
import Alert from '../includes/Alert';

import * as Helper from '../../helpers/functions/general';

function RestaurantContact(props){

    //REDUX
    const options = useSelector(state => state.options);
    
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] =  useState(false)
    
    const [errorForm, setErrorForm] = useState({})
    const [ApiResponse, setApiReponse] = useState(null)

    //OPTIONS
    const [optionSubjects, setOptionSubjects] =  useState([])

    //INPUTS
    const [email, setEmail] =  useState('')
    const [subject, setSubject] =  useState(null)
    const [message, setMessage] =  useState('')

    useEffect(() => {
        if(options){
            setOptionSubjects(options.message_restaurant_options)
        }
    }, [options]);

    const handleInputChange = (event) => {
        const { name, value, options } = event.target;
        switch(name) {
            case 'email':
                setEmail(value)
            break;
            case 'subject':
                setSubject({
                    id: value,
                    name: options[event.target.selectedIndex].text
                })
            break;          
            case 'message':
                setMessage(value)
            break;
            default:
            break;
        }
    }
    
    const handleFormSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        
        if(!validateForm()){
            setIsLoading(true)
            sendMessageRestaurant()
        }
    }
    
    const validateForm = () => {
        let errors = {}

        if(email.length <= 0){
            errors.email = 'Email is required'
        }else{
            if(Helper.regexEmail(email)){
                errors.email = false
            }else{
                errors.email = 'Enter a valid email'
            }
        }

        errors.subject = subject ? false : 'Issue is required'
        errors.message = message ? false : 'Description is required'
        
        setErrorForm(errors)

        return Object.keys(errors).some(data => {
            return errors[data];
        })
    }
    
    const sendMessageRestaurant = () => {
        ApiMail.messageRestaurant({
            restaurant_id: props.restaurant_id,
            restaurant_name: props.restaurant_name,
            restaurant_address: props.restaurant_address,
            email: email,
            subject: subject,
            message: message
        })
        .then((res) => {
            setIsLoading(false)
            setEmailSent(true)
        })
        .catch((error) => {
            setIsLoading(false)
            setApiReponse(error.response.data)
        })
    }

    const renderForm = () => {
        return (
            <form id="restaurant-contact-form" className="modal-body" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    <label>What is the issue?*</label>
                    <select 
                        name="subject"
                        className={`form-control form-control-sm custom-select ${!errorForm.subject || 'error'}`}
                        defaultValue={""}
                        onChange={handleInputChange}
                    >
                        <option hidden disabled value=""></option>
                        {optionSubjects.map((data, i) =>
                            <option key={i} value={data.value}>{data.label}</option>
                        )}
                    </select>
                    <ErrorMessage
                        message={errorForm.subject}
                    />
                </div>
                <div className="form-group">
                    <label>Email*</label>
                    <input className={`form-control ${!errorForm.email || 'error'}`} name="email" value={email} onChange={handleInputChange}/>
                    <ErrorMessage
                        message={errorForm.email}
                    />
                </div>
                <div className="form-group">
                    <label>Description of issue*</label>
                    <textarea className={`form-control ${!errorForm.message || 'error'}`} name="message" type="text" rows="4" maxLength="255" value={message} onChange={handleInputChange}></textarea>
                    <ErrorMessage
                        message={errorForm.message}
                    />
                </div>
                <button className="btn" disabled={isLoading}>
                    Submit
                </button>
                <Alert
                    content={ApiResponse}
                />
            </form>
        )
    }
    
    return (
        <div className="restaurant-contact-container">
            {!emailSent ? 
                renderForm() 
            :
                <Message
                    message={'Thanks for reaching out, we will get back to you soon!'}
                    toggleForm={() => props.toggleRestaurantContactForm()}
                />
            }
        </div>
    )   
}

export default RestaurantContact