import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  MarkerClusterer as MarkerClusterComponent,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import DirectionIcon from "@material-ui/icons/Directions";
import ShareIcon from "@material-ui/icons/Share";

import * as MapConstants from "../../../helpers/map_options";
import * as Helpers from "../../../helpers/functions/general";

function MarkerCluster(props) {
  const { restaurants } = props;

  //REDUX
  const dispatch = useDispatch();
  const infoWindow = useSelector((state) => state.infoWindow);
  const polygon = useSelector((state) => state.polygon);
  const searchRestaurant = useSelector((state) => state.searchRestaurant);
  const restaurantAdd = useSelector((state) => state.restaurantAdd);
  const restaurantShare = useSelector((state) => state.restaurantShare);

  const viewRestaurant = (id) => {
    dispatch({ type: "restaurantView", payload: id });
    dispatch({ type: "user_action", action: "view_restaurant_user" });
  };

  const closeInfoWindow = () => {
    if (restaurantShare) {
      dispatch({ type: "restaurantShare", payload: false });
    }

    if (searchRestaurant) {
      dispatch({ type: "searchRestaurant", payload: null });
    }

    dispatch({ type: "infoWindow", payload: null });
  };

  return polygon || restaurantShare ? (
    <MarkerClusterComponent options={MapConstants.map_options.cluster_styles}>
      {(clusterer) =>
        restaurants.map((data) => (
          <Marker
            key={data.id}
            position={data.position}
            clusterer={clusterer}
            options={MapConstants.map_options.restaurant_marker_style}
            onClick={() => dispatch({ type: "infoWindow", payload: data.id })}
          >
            {infoWindow === data.id && (
              <InfoWindow
                position={data.position}
                onCloseClick={closeInfoWindow}
              >
                <div className="info-window">
                  <div
                    className={`name-actions ${
                      restaurantAdd.action ? "add" : ""
                    }`}
                  >
                    <h6 className="name">{data.name}</h6>
                    <h6 className="rating">
                      {Helpers.formatNumbers(data.rating)} likes
                    </h6>
                    {!restaurantAdd.action ? (
                      <div className="actions">
                        <a
                          className="btn direction"
                          href={Helpers.generateDirectionsURL(data.position)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DirectionIcon />
                        </a>
                        <button
                          className="btn btn-sm share"
                          onClick={(e) => Helpers.generateShareLink(e, data)}
                        >
                          <ShareIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                  {!restaurantAdd.action ? (
                    <button
                      className="btn view"
                      data-toggle="modal"
                      data-target="#modal"
                      onClick={() => viewRestaurant(data.id)}
                    >
                      View
                    </button>
                  ) : null}
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))
      }
    </MarkerClusterComponent>
  ) : null;
}

export default MarkerCluster;
