import * as GeneralHelpers from "./general";
import * as Constants from "../constants";

import store from "../../redux/store";

export const getMapParameters = () => {
  let bounds = store.getState().map.getBounds();
  return {
    bounds: bounds,
    center: bounds.getCenter(),
    neLatLng: bounds.getNorthEast(),
    swLatLng: bounds.getSouthWest(),
  };
};

export const getCircleParameters = () => {
  let bounds = store.getState().mapCircle.getBounds();
  return {
    bounds: bounds,
    center: bounds.getCenter(),
  };
};

export const isWithinCircleBounds = () => {
  if (
    !getCircleParameters().bounds.contains(getMapParameters().neLatLng) ||
    !getCircleParameters().bounds.contains(getMapParameters().swLatLng)
  ) {
    return true;
  }
};

export const isWithinPolygonBounds = () => {
  let bounds = store.getState().polygon.bounds;

  if (
    bounds.contains(getMapParameters().neLatLng) &&
    bounds.contains(getMapParameters().swLatLng)
  ) {
    return true;
  } else {
    return false;
  }
};

export const isWithinPolygonArrayBounds = () => {
  let restaurantAdd = store.getState().restaurantAdd;

  let polygonArray = store.getState().polygonArray;
  let polygon = store.getState().polygon;

  for (let index = 0; index < polygonArray.length; index++) {
    let bounds = polygonArray[index].polygon;
    if (
      window.google.maps.geometry.poly.containsLocation(getMapParameters().neLatLng, bounds) &&
      window.google.maps.geometry.poly.containsLocation(getMapParameters().swLatLng, bounds)
    ) {
      store.dispatch({ type: "polygon", payload: polygonArray[index] });

      if (!polygon) {
        if (!restaurantAdd.action) {
          store.dispatch({
            type: "snackbar",
            duration: 4000,
            message: `Entered ${polygonArray[index].country.name}`,
          });
        } else {
          if (restaurantAdd.action !== "marked") {
            store.dispatch({
              type: "snackbar",
              buttons: true,
              message: "Pin or search for a restaurant",
            });
          }
        }
      }
      return true;
    }
  }
};

export const generateSearchCountryCodes = () => {
  // let polygonArray = store.getState().polygonArray;
  let polygon = store.getState().polygon;

  if (polygon) {
    return [polygon.country.code];
  } 
  // else {
  //   return polygonArray.map((data) => {
  //     return data.country.code;
  //   });
  // }
};

export const generatePolygonArray = () => {
  let countries = store.getState().options.countries.map((data) => data.label);

  return Constants.country_polygons
    .filter((data) => {
      if (countries.includes(data.name)) {
        return true;
      }
      return false;
    })
    .map((data) => {
      return {
        country: {
          code: data.code,
          name: data.name,
        },
        paths: data.paths,
        bounds: generatePolygonBounds(data.paths),
        polygon: new window.google.maps.Polygon({ paths: data.paths }),
      };
    });
};

export const generatePolygonBounds = (polygon) => {
  let bounds = new window.google.maps.LatLngBounds();
  for (let i = 0; i < polygon.length; i++) {
    bounds.extend(polygon[i]);
  }
  return bounds;
};

export const generateCircleRadius = () => {
  let map = store.getState().map;

  let bounds = map.getBounds();
  let center = bounds.getCenter();
  let ne = bounds.getNorthEast();
  let r = 6731;

  let lat1 = center.lat() / 57.2958;
  let lon1 = center.lng() / 57.2958;
  let lat2 = ne.lat() / 57.2958;
  let lon2 = ne.lng() / 57.2958;

  let dis =
    r *
    Math.acos(
      Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
    );

  let radius = dis * 1000;
  return radius;
};

export const filterSearchRestaurant = () => {
  let restaurants = store.getState().restaurants;
  let searchRestaurant = store.getState().searchRestaurant.id;
  return restaurants.filter((data) => data.id === searchRestaurant);
};

export const getPixelPositionOffset = (width, height) => {
  return {
    x: -width / 2,
    y: -height / 2,
  };
};

export const fitCountryToBounds = (bounds) => {

  let map = store.getState().map;

  let neLatLng = bounds.getNorthEast();
  let swLatLng = bounds.getSouthWest();

  let fit = new window.google.maps.LatLngBounds();
  fit.extend(neLatLng);
  fit.extend(swLatLng);
  map.fitBounds(fit);
};

export const reverseGeocodeLatLng = (autocomplete, position) => {
  if (!autocomplete) {
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          let formatted_address = formatGeocodeAddress(results[0]);
          formatted_address.position = position;

          store.dispatch({
            type: "restaurantAdd",
            action: "confirm",
            position: position,
            address: formatted_address,
          });
          store.dispatch({
            type: "user_action",
            action: "add_restaurant_user",
          });
        } else {
          alert("No results found");
        }
      } else {
        alert("Geocoder failed due to: " + status);
      }
    });
  } else {
    let formatted_address = formatGeocodeAddress(autocomplete);
    formatted_address.position = position;

    store.dispatch({
      type: "restaurantAdd",
      action: "confirm",
      position: position,
      place: {
        name: autocomplete.name,
        phone_number: autocomplete.international_phone_number,
      },
      address: formatted_address,
    });
    store.dispatch({ type: "user_action", action: "add_restaurant_user" });
  }
};

export const formatGeocodeAddress = (data) => {
  let address = {};

  data.address_components.forEach((data) => {
    //ADDRESS OR CITY
    if (data.types.includes("neighborhood")) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.address = data.long_name;
    } else if (
      data.types.includes("sublocality") ||
      data.types.includes("sublocality_level_1")
    ) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.address = data.long_name;
    }

    //ADDRESS OR CITY
    if (data.types.includes("neighborhood")) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.address = data.long_name;
    } else if (
      data.types.includes("sublocality") ||
      data.types.includes("sublocality_level_1")
    ) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.address = data.long_name;
    }

    //STATE
    if (data.types.includes("administrative_area_level_1")) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.state = data.long_name;
    }

    //COUNTRY
    if (data.types.includes("country")) {
      if (GeneralHelpers.regexEnglish(data.long_name))
        address.country = data.long_name;
    }
  });

  return address;
};