import axios from 'axios';

export const getOptions = async () => {
    return await axios.get(process.env.NODE_ENV === 'production' ?  '/api/options' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/options`);
}

export const getCountries = async () => {
    return await axios.get(process.env.NODE_ENV === 'production' ? '/api/options/countries' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/options/countries`);
}

export const getStates = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ? '/api/options/states' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/options/states`, params);
}

export const getMeatCountries = async (params) => {
    return await axios.post(process.env.NODE_ENV === 'production' ? '/api/options/meats' : `http://localhost:${process.env.REACT_APP_PORT_BACKEND}/api/options/meats`, params);
}