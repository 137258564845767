import React, { useEffect, useState, memo } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import {
  useLoadScript,
  GoogleMap,
  Circle,
  Marker,
} from "@react-google-maps/api";

import $ from "jquery";

import * as ApiRestaurant from "../api/restaurants";

import * as MapHelpers from "../helpers/functions/map";
import * as MapConstants from "../helpers/map_options";

import TopBar from "./Topbar";

import AutoComplete from "./includes/Map/Autocomplete";
import MapControls from "./includes/Map/MapControls";
import Polygon from "./includes/Map/Polygon";
import OverlayView from "./includes/Map/OverlayView";
import MarkerCluster from "./includes/Map/MarkerCluster";

import AddIcon from "@material-ui/icons/Add";

function Map(props) {

  //REACT ROUTER
  const { restaurant_id, restaurant_url } = useParams();

  //REDUX
  const dispatch = useDispatch();
  const options = useSelector((state) => state.options);
  const auth = useSelector((state) => state.auth);
  const map = useSelector((state) => state.map);
  const mapZoom = useSelector((state) => state.mapZoom);
  const polygon = useSelector((state) => state.polygon);
  const polygonArray = useSelector((state) => state.polygonArray);
  const userLocation = useSelector((state) => state.userLocation);
  const searchRestaurant = useSelector((state) => state.searchRestaurant);
  const restaurantItems = useSelector((state) => state.restaurants);
  const restaurantAdd = useSelector((state) => state.restaurantAdd);
  const restaurantShare = useSelector((state) => state.restaurantShare);

  const [isBoundsRendered, setIsBoundsRendered] = useState(false);

  //MAP VARIABLES
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: MapConstants.map_options.libraries,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: 25.584455768829574,
    lng: 50.10535059701605,
  });
  const [isMapMoving, setIsMapMoving] = useState(false);

  //CIRCLE VARIABLES
  const [mapCircleCenter, setMapCircleCenter] = useState(null);
  const [mapCircleRadius, setMapCircleRadius] = useState(null);

  //RESTAURANTS
  const [restaurants, setRestaurants] = useState([]);

  //SEARCH AUTOCOMPLETE
  const [searchAutoComplete, setSearchAutoComplete] = useState("");
  const [restaurantAutocomplete, setRestaurantAutocomplete] = useState(null);

  useEffect(() => {
    if(isMapMoving){
      dispatch({ type: "polygon", payload: null });
    }
  }, [isMapMoving]);
  
  //MAP BOUNDS RENDERING
  useEffect(() => {
    if (isBoundsRendered) {
      if (restaurant_id && restaurant_url) {
        getShareRestaurant();
      }

      getRestaurantsCount();
    }
  }, [isBoundsRendered]);

  //CIRCLE RADIUS
  useEffect(() => {
    if (polygon && mapCircleRadius && !restaurantShare) {
      getAllRestaurants();
    }
  }, [mapCircleRadius, restaurantShare]);

  //POLYGON ARRAY
  useEffect(() => {
    if (isBoundsRendered && polygonArray.length === options.countries.length) {
      props.handleMapLoaded(true);
    }
  }, [polygonArray]);

  //RESTAURANT ADD
  useEffect(() => {
    if (isBoundsRendered) {
      if (restaurantAdd.action) {
        switch (restaurantAdd.action) {
          case "add":
            if (polygon) {

              if(searchAutoComplete.length > 0){
                handleClearAutoComplete()
              }

              dispatch({
                type: "snackbar",
                buttons: true,
                message: "Pin or search for a restaurant",
              });
            } else {
              dispatch({
                type: "snackbar",
                buttons: true,
                message: "Enter a country to pin a restaurant",
              });
            }
            return;
          case "get":
            MapHelpers.reverseGeocodeLatLng(
              restaurantAutocomplete,
              restaurantAdd.position
            );
            return;
          default:
            break;
        }
      } else {
        setRestaurantAutocomplete(null);
        handleClearAutoComplete();

        if (polygon && mapCircleRadius) {
          getAllRestaurants();
        }
      }
    }
  }, [restaurantAdd]);

  //RESTAURANT ITEMS
  useEffect(() => {
    if (searchRestaurant) {
      setRestaurants(MapHelpers.filterSearchRestaurant());
    } else {
      setRestaurants(restaurantItems);
    }
  }, [restaurantItems]);

  //SEARCH
  useEffect(() => {
    if (searchRestaurant) {
      let filtered_search_restaurants = MapHelpers.filterSearchRestaurant();
      if (filtered_search_restaurants.length > 0) {
        setRestaurants(filtered_search_restaurants);
      } else {
        setIsMapMoving(true);
      }

      if (mapZoom < 18) {
        dispatch({ type: "mapZoom", payload: 18 });
      }

      setMapCenter(searchRestaurant.position);
      setMapCircleCenter(searchRestaurant.position);
      dispatch({ type: "infoWindow", payload: searchRestaurant.id });
    } else {
      setRestaurants(restaurantItems);
      dispatch({ type: "infoWindow", payload: null });
    }
  }, [searchRestaurant]);

  useEffect(() => {
    if (map && !restaurantShare) {
      setMapCircleCenter(MapHelpers.getMapParameters().center);
      setMapCircleRadius(MapHelpers.generateCircleRadius());
    }
  }, [restaurantShare]);

  //USER LOCATION
  useEffect(() => {
    if (userLocation.get) {
      getUserLocation();
    }

    if (userLocation.position) {
      setMapCenter(userLocation.position);
    }
  }, [userLocation]);

  const onBoundsChanged = () => {
    if (!isBoundsRendered) {
      setIsBoundsRendered(true);
    } else {
      if (polygon) {
        checkPolygonBounds();
      } else {
        checkPolygonArrayBounds();
      }
    }
  };

  const onIdle = () => {
    if (isMapMoving) {
      setIsMapMoving(false);
      checkPolygonArrayBounds();
    }
  };

  const onZoomChanged = () => {
    if (isBoundsRendered) {
      dispatch({ type: "mapZoom", payload: map.getZoom() });
    }
  };

  const onDrag = () => {
    if (isBoundsRendered) {
      //ADD RESTAURANT MARKER
      if (restaurantAdd.position) {
        let mapBounds = map.getBounds();
        let center = mapBounds.getCenter();
        dispatch({
          type: "restaurantAdd",
          action: "marked",
          position: {
            lat: center.lat(),
            lng: center.lng(),
          },
        });

        if (restaurantAutocomplete) {
          handleClearAutoComplete();
        }
      }
    }
  };

  const handleSetRestaurantMarked = (position) => {
    if (restaurantAdd.action !== "marked") {
      dispatch({
        type: "snackbar",
        buttons: true,
        message: "Confirm restaurant location?",
      });
    }

    dispatch({
      type: "restaurantAdd",
      action: "marked",
      position: position,
    });

    setMapCenter(position);
  };

  const handleAddRestaurant = () => {
    dispatch({ type: "isSearch", payload: false });
    dispatch({ type: "restaurantAdd", action: "add" });
  };

  const handleSetSearchAutoComplete = (data) => setSearchAutoComplete(data);

  const handleSetRestaurantAutoComplete = (data) =>
    setRestaurantAutocomplete(data);

  const handleClearAutoComplete = () => {
    setRestaurantAutocomplete(null);
    setSearchAutoComplete("");
    $(".autocomplete-input").val("");
  };

  const checkCircleBounds = () => {
    if (MapHelpers.isWithinCircleBounds()) {
      setMapCircleCenter(MapHelpers.getMapParameters().center);
      setMapCircleRadius(MapHelpers.generateCircleRadius());
    }
  };

  const checkPolygonBounds = () => {
    if (MapHelpers.isWithinPolygonBounds()) {
      checkCircleBounds();
    } else {
      setMapCircleCenter(null);
      setMapCircleRadius(null);

      dispatch({ type: "polygon", payload: null });

      if (!restaurantAdd.action) {
        dispatch({
          type: "snackbar",
          duration: 4000,
          message: `Exited  ${polygon.country.name}`,
        });
      } else {
        dispatch({ type: "restaurantAdd", action: "add", position: null });
        handleClearAutoComplete();
      }

      if (!restaurantShare) {
        dispatch({ type: "searchRestaurant", payload: null });
        dispatch({ type: "restaurantShare", payload: false });

        dispatch({ type: "restaurants", payload: [] });
        dispatch({ type: "infoWindow", payload: null });
      }
    }
  };

  const checkPolygonArrayBounds = () => {
    if (MapHelpers.isWithinPolygonArrayBounds()) {
      setMapCircleCenter(MapHelpers.getMapParameters().center);
      setMapCircleRadius(MapHelpers.generateCircleRadius());
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((data) => {
        let position = {
          lat: data.coords.latitude,
          lng: data.coords.longitude,
        };

        setIsMapMoving(true);

        if (mapZoom < 18) {
          dispatch({ type: "mapZoom", payload: 18 });
        }

        if (restaurantAdd.action) {
          handleSetRestaurantMarked(position);
        }

        dispatch({
          type: "userLocation",
          position: position,
        });
      });
    } else {
      alert("Not Supported on Browser");
    }
  };

  const getRestaurantsCount = () => {
    ApiRestaurant.getRestaurantsCount()
      .then((res) => {
        dispatch({ type: "restaurantCount", payload: res.data });

        dispatch({
          type: "polygonArray",
          payload: MapHelpers.generatePolygonArray(),
        });
      })
      .catch((error) => {
        alert("Error occured please refresh the page");
      });
  };

  const getShareRestaurant = () => {
    ApiRestaurant.getShareRestaurant({
      restaurant_id: restaurant_id,
      restaurant_url: restaurant_url,
    })
      .then((res) => {
        setMapCenter(res.data.position);

        dispatch({ type: "mapZoom", payload: 18 });

        dispatch({ type: "restaurantShare", payload: true });
        dispatch({ type: "restaurants", payload: [res.data] });

        dispatch({ type: "infoWindow", payload: res.data.id });
      })
      .catch((error) => {
        alert("Error occured please refresh the page");
      });
  };

  const getAllRestaurants = () => {
    ApiRestaurant.getAllRestaurants({
      country: polygon.country.name,
      position: mapCircleCenter,
      radius: mapCircleRadius,
    })
      .then((res) => {
        dispatch({ type: "restaurants", payload: res.data });
      })
      .catch((error) => {
        alert("Error occured please refresh the page");
      });
  };

  const renderMap = () => {
    return (
      <div className="map-container">
        {restaurantAdd.action || <TopBar />}

        <MapControls getUserLocation={getUserLocation} />

        <div className="map">
          <GoogleMap
            mapContainerStyle={MapConstants.map_options.container_style}
            options={MapConstants.map_options.map_styles}
            onLoad={(e) => dispatch({ type: "map", payload: e })}
            onUnmount={() => dispatch({ type: "map", payload: null })}
            onIdle={onIdle}
            onBoundsChanged={onBoundsChanged}
            onZoomChanged={onZoomChanged}
            onDrag={onDrag}
            center={mapCenter}
            zoom={mapZoom}
          >
            {/* PLACES AUTOCOMPLETE */}
            {polygon && restaurantAdd.action ? (
              <AutoComplete
                searchAutoComplete={searchAutoComplete}
                restaurantAutocomplete={restaurantAutocomplete}
                handleSetRestaurantMarked={handleSetRestaurantMarked}
                handleSetSearchAutoComplete={handleSetSearchAutoComplete}
                handleSetRestaurantAutoComplete={
                  handleSetRestaurantAutoComplete
                }
                handleClearAutoComplete={handleClearAutoComplete}
              />
            ) : null}

            {/* USER LOCATION MARKER */}
            {userLocation.position && !restaurantAdd.action ? (
              <Marker
                position={userLocation.position}
                options={MapConstants.map_options.user_location_marker_style}
              />
            ) : null}

            {/* RESTAURANT ADD MARKER */}
            {!restaurantAdd.position || (
              <Marker
                position={restaurantAdd.position}
                options={MapConstants.map_options.add_restaurant_marker_style}
              />
            )}

            <Circle
              onLoad={(e) => dispatch({ type: "mapCircle", payload: e })}
              onUnmount={() => dispatch({ type: "mapCircle", payload: null })}
              radius={mapCircleRadius}
              center={mapCircleCenter}
              options={MapConstants.map_options.circle_options}
            />

            <Polygon
              restaurantAutocomplete={restaurantAutocomplete}
              handleSetRestaurantMarked={handleSetRestaurantMarked}
              handleClearAutoComplete={handleClearAutoComplete}
            />

            {restaurantShare || <OverlayView />}

            <MarkerCluster restaurants={restaurants} />
          </GoogleMap>
        </div>
        {auth && !restaurantShare && !restaurantAdd.action ? (
          <button className="btn btn-add" onClick={handleAddRestaurant}>
            <AddIcon />
          </button>
        ) : null}
      </div>
    );
  };

  return isLoaded ? renderMap() : null;
}
export default memo(Map);