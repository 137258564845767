import React from 'react';
import ReactDOM from 'react-dom';

import {
  BrowserRouter,
} from "react-router-dom";

import * as serviceWorker from './serviceWorker';

import './styles/app.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App'

ReactDOM.hydrate(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
