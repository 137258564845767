const INITIAL_STATE = {
    action: null,
    fullscreen: false,
    disable_close: false
}

export const userActionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'user_action':
            return {
                action: action.action,
                fullscreen: action.fullscreen || INITIAL_STATE.fullscreen,
                disable_close: action.disable_close || INITIAL_STATE.disable_close
            }
        default:
            return state       
    }
}
export default userActionReducer;