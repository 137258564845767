import React from 'react';

import { Provider } from 'react-redux';
import store from './redux/store';

import {
  Switch,
  Route,
} from "react-router-dom";

import Index from './views/Index';
import ResetPassword from './views/ResetPassword';

import Snackbar from './components/includes/Snackbar';
import Modal from './components/Modal';

function App() {
  return (
    <Provider store={store}>
        <Switch>
          <Route path="/forgot-password/:forgot_password_unique_id" component={ResetPassword}/>
          <Route path="/:restaurant_id/:restaurant_url" component={Index}/>
          <Route exact path="/" component={Index}/>
        </Switch>
        <Modal/>
        <Snackbar/>
    </Provider>
  );
}

export default App;