import React, { useState } from 'react'

import { useDispatch } from "react-redux";

import CloseIcon from '@material-ui/icons/Close';

import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';

import $ from 'jquery'

function SignIn(){

    //REDUX
    const dispatch = useDispatch();

    const [view, setView] =  useState('Sign in')

    const handleCloseModal = () => { 
        dispatch({type: 'user_action', action: null})
    }

    const handleForm = (data) => setView(data)

    const renderForm = () => {
        switch(view) {
            case 'Register':
                return <Register
                    handleForm={handleForm}
                />
            case 'Forgot Password':
                return <ForgotPassword
                    handleForm={handleForm}
                />
            case 'Sign in':
                return <Login
                    handleCloseModal={handleCloseModal}
                    handleForm={handleForm}
                /> 
            default:
               break;
        }
    }

    return (
        <div className="modal-content sign-in-container">
            <div className="modal-header">
                <h6 className="text">{view}</h6>
                <button
                    className="btn"
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </button>
            </div>
            
            <div className="modal-body">
                {renderForm()}
            </div>
        </div>
    )   
}


export default SignIn