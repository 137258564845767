import React from "react";

import { useSelector, useDispatch } from "react-redux";

import LocationIcon from "@material-ui/icons/MyLocation";
import ZoomInIcon from "@material-ui/icons/Add";
import ZoomOutIcon from "@material-ui/icons/Remove";

function MapControls(props) {

  //PROPS
  const { getUserLocation } = props

  //REDUX
  const dispatch = useDispatch();
  const mapZoom = useSelector((state) => state.mapZoom);
  const restaurantShare = useSelector((state) => state.restaurantShare);

  return (
    <div className="map-controls btn-group-vertical">
      {restaurantShare || <button type="button" className="btn" onClick={getUserLocation}>
        <LocationIcon />
      </button>}
      <button
        type="button"
        className="btn"
        onClick={() => dispatch({ type: "mapZoom", payload: mapZoom + 1 })}
      >
        <ZoomInIcon />
      </button>
      <button
        type="button"
        className="btn"
        onClick={() => dispatch({ type: "mapZoom", payload: mapZoom - 1 })}
      >
        <ZoomOutIcon />
      </button>
    </div>
  );
}

export default MapControls;
