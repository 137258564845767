import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import * as ApiMail from "../api/message";

import CloseIcon from "@material-ui/icons/Close";

import ErrorMessage from "./includes/ErrorMessage";
import Message from "./includes/Message";
import Alert from "./includes/Alert";

import * as Helper from "../helpers/functions/general";

function Contact() {
  //REDUX
  const dispatch = useDispatch();
  const options = useSelector((state) => state.options);

  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const [errorForm, setErrorForm] = useState({});
  const [ApiResponse, setApiReponse] = useState(null);

  //OPTIONS
  const [optionSubjects, setOptionSubjects] = useState([]);

  //INPUTS
  const [subject, setSubject] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (options) {
      setOptionSubjects(options.message_options);
    }
  }, [options]);

  const handleInputChange = (event) => {
    const { name, value, options } = event.target;
    switch (name) {
      case "subject":
        setSubject({
          id: value,
          name: options[event.target.selectedIndex].text,
        });
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleFormSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!validateForm()) {
      setIsLoading(true);
      sendMessageContact();
    }
  };

  const validateForm = () => {
    let errors = {};

    errors.subject = subject ? false : "Subject is required";

    if (email.length <= 0) {
      errors.email = "Email is required";
    } else {
      if (Helper.regexEmail(email)) {
        errors.email = false;
      } else {
        errors.email = "Enter a valid email";
      }
    }

    errors.message = message ? false : "Description is required";

    setErrorForm(errors);

    return Object.keys(errors).some((data) => {
      return errors[data];
    });
  };

  const closeModal = () => {
    dispatch({ type: "user_action", action: null });
  };

  const sendMessageContact = () => {
    ApiMail.messageContact({
      subject: subject,
      email: email,
      message: message,
    })
      .then((res) => {
        setIsLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setApiReponse(error.response.data);
      });
  };

  const renderForm = () => {
    return (
      <form
        id="contact-form"
        className="modal-body"
        onSubmit={handleFormSubmit}
      >
        <div className="form-group">
          <label>Subject*</label>
          <select
            name="subject"
            className={`form-control form-control-sm custom-select ${
              !errorForm.subject || "error"
            }`}
            defaultValue={""}
            onChange={handleInputChange}
          >
            <option hidden disabled value=""></option>
            {optionSubjects.map((data, i) => (
              <option key={i} value={data.value}>
                {data.label}
              </option>
            ))}
          </select>
          <ErrorMessage message={errorForm.subject} />
        </div>
        <div className="form-group">
          <label>Email*</label>
          <input
            className={`form-control ${!errorForm.email || "error"}`}
            name="email"
            value={email}
            onChange={handleInputChange}
          />
          <ErrorMessage message={errorForm.email} />
        </div>
        <div className="form-group">
          <label>Description*</label>
          <textarea
            className={`form-control ${!errorForm.message || "error"}`}
            name="message"
            type="text"
            rows="4"
            maxLength="255"
            value={message}
            onChange={handleInputChange}
          ></textarea>
          <ErrorMessage message={errorForm.message} />
        </div>
        <button className="btn" disabled={isLoading}>
          Submit
        </button>
        <Alert content={ApiResponse} />
      </form>
    );
  };

  return (
    <div className="modal-content contact-container">
      <div className="modal-header">
        <h6 className="text">Get in touch</h6>
        <button className="btn" onClick={closeModal}>
          <CloseIcon />
        </button>
      </div>

      {!emailSent ? (
        renderForm()
      ) : (
        <Message
          message={"Thanks for reaching out, we will get back to you soon!"}
        />
      )}
    </div>
  );
}

export default Contact;
