import React, { useRef, useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import * as Helper from "../../helpers/functions/general";

import MenuIcon from "@material-ui/icons/Menu";

function Menu(props) {
  const ref = useRef(null);

  const { isMenuOpen, handleToggleMenu } = props;

  //REDUX
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const restaurantShare = useSelector((state) => state.restaurantShare);

  useEffect(() => {
    const pageClickEvent = (e) => {
      if (ref.current !== null && !ref.current.contains(e.target)) {
        handleToggleMenu(!isMenuOpen);
      }
    };

    if (isMenuOpen) {
      window.addEventListener("click", pageClickEvent);
    }

    return () => {
      window.removeEventListener("click", pageClickEvent);
    };
  }, [isMenuOpen]);

  const toggleDropdown = () => {
    handleToggleMenu(!isMenuOpen);
  };

  const toggleModal = (action) => {
    toggleDropdown();
    switch (action) {
      case "login_user":
        return dispatch({ type: "user_action", action: "login_user" });
      case "account_user":
        return dispatch({ type: "user_action", action: "account_user" });
      case "contact_user":
        return dispatch({ type: "user_action", action: "contact_user" });
      default:
        break;
    }
  };

  const handleAddRestaurant = () => {
    if (!auth) {
      dispatch({
        type: "snackbar",
        duration: 4000,
        message: "Sign in to add a restaurant",
      });
    } else {
      dispatch({ type: "isSearch", payload: false });
      dispatch({ type: "restaurantAdd", action: "add" });
    }
  };

  return (
    <div className={`menu-container ${restaurantShare ? "share" : ""}`}>
      <button
        className={`btn btn-menu ${restaurantShare ? "share" : ""}`}
        onClick={toggleDropdown}
      >
        {!auth ? <MenuIcon /> : Helper.generateAvatar(auth)}
      </button>

      <ul
        ref={ref}
        className={`list-group ${isMenuOpen ? "open" : ""} ${
          restaurantShare ? "share" : ""
        }`}
      >
        {!auth || (
          <li
            className="list-group-item cursor-pointer"
            onClick={() => toggleModal("account_user")}
          >
            Account
          </li>
        )}

        {!restaurantShare ? (
          <li
            className="list-group-item cursor-pointer"
            onClick={handleAddRestaurant}
          >
            Add Restaurant
          </li>
        ) : null}

        <li
          className="list-group-item cursor-pointer"
          onClick={() => toggleModal("contact_user")}
        >
          Get in touch
        </li>

        <a
          className="list-group-item cursor-pointer"
          href="https://www.paypal.com/paypalme/harleylapuz"
          target="_blank"
          rel="noreferrer"
        >
          Buy us a Shawarma
        </a>

        {!auth ? (
          <li
            className="list-group-item cursor-pointer"
            onClick={() => toggleModal("login_user")}
          >
            Sign in
          </li>
        ) : (
          <a className="list-group-item" href="/auth/logout">
            Logout
          </a>
        )}
      </ul>
    </div>
  );
}

export default Menu;
