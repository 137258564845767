import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import Spinner from "../components/includes/Spinner";
import Map from "../components/Map";

import $ from "jquery";

import * as ApiAuth from "../api/auth";
import * as ApiOptions from "../api/options";

function Index() {

  //REDUX
  const dispatch = useDispatch();

  const [renderMap, setRenderMap] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    getOptions()
  }, []);

  useEffect(() => {
    if (mapLoaded) {
      ApiAuth.authCheck()
      $(".fullscreen-loading").fadeOut(() => {
        $(".fullscreen-loading").remove();
      });
    }
  }, [mapLoaded]);

  const handleMapLoaded = (data) => setMapLoaded(data);

  const getOptions = () => {
    ApiOptions.getOptions()
      .then((res) => {
        dispatch({ type: "options", payload: res.data })
        setRenderMap(true)
      })
      .catch((error) => {
        alert("Error occured please refresh the page");
      });
  };

  return (
    <div className="wrapper">
      <div className="fullscreen-loading">
        <Spinner />
      </div>

      {!renderMap || <Map handleMapLoaded={handleMapLoaded} />}
    </div>
  );
}

export default Index;