export const map_options = {
    libraries: ['places', 'geometry'],
    container_style: {
        width: "100%",
        height: "100%"
    },
    map_styles: {
        fullscreenControl: false,
        clickableIcons: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        minZoom: 5,
        maxZoom: 22,
        styles: [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "weight": "10.00"
                    }
                ]
            }
        ]
    },
    polygon_options: {
        fillColor: "lightblue",
        strokeColor: "red",
        strokeWeight: 2,
        // strokeOpacity: 0.8,
        // fillOpacity: 0.35,
        strokeOpacity: 0,
        fillOpacity: 0,
        clickable: true,
        draggable: false,
    },
    autocomplete_options: {
        fields: ['address_components', 'geometry', 'name', 'international_phone_number']
    },
    circle_options: {
        strokeColor: '#000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#000',
        fillOpacity: 0,
        clickable: false,
        draggable: false,
        editable: false,
        // visible: process.env.NODE_ENV === 'production' ? false : true,
        visible: false,
        zIndex: 1
    },
    cluster_styles: {
        maxZoom: 19,
        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    },
    user_location_marker_style: {
        icon: "/images/markers/user_marker.png" 
    },
    add_restaurant_marker_style: {
        icon: "/images/markers/add_marker.png",
        draggable: false
    },
    restaurant_marker_style: {
        icon: "/images/markers/restaurant_marker.png" 
    }
}