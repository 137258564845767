import React, { useEffect, useState } from "react";

import { Polygon as PolygonComponent } from "@react-google-maps/api";

import { useSelector, useDispatch } from "react-redux";

import * as MapHelpers from "../../../helpers/functions/map";

import * as MapConstants from "../../../helpers/constants";
import * as MapOptions from "../../../helpers/map_options";

function Polygon(props) {
  //PROPS
  const {
    restaurantAutocomplete,
    handleSetRestaurantMarked,
    handleClearAutoComplete,
  } = props;

  //REDUX
  const dispatch = useDispatch();
  const mapZoom = useSelector((state) => state.mapZoom);
  const polygon = useSelector((state) => state.polygon);
  const polygonArray = useSelector((state) => state.polygonArray);
  const searchRestaurant = useSelector((state) => state.searchRestaurant);
  const restaurantAdd = useSelector((state) => state.restaurantAdd);
  const restaurantShare = useSelector((state) => state.restaurantShare);

  const onClick = (e, data) => {
    if (!polygon) {
      MapHelpers.fitCountryToBounds(data.bounds);
    } else {
      if (!searchRestaurant) {
        dispatch({ type: "infoWindow", payload: null });
      }

      if (restaurantAdd.action) {
        let position = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };

        if (mapZoom < 18) {
          dispatch({ type: "mapZoom", payload: 18 });
        }

        if (restaurantAutocomplete) {
          handleClearAutoComplete();
        }

        handleSetRestaurantMarked(position);
      }
    }
  };

  return polygonArray.map((data, i) => (
    <PolygonComponent
      key={i}
      options={MapOptions.map_options.polygon_options}
      paths={data.paths}
      onClick={(e) => (!restaurantShare ? onClick(e, data) : null)}
    />
  ));
}

export default Polygon;
